// CardValoracion.js

import React, { useContext } from "react";
import { StyleSheet, View, Text, Image, TouchableOpacity } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Fonts, Colors } from "../styles/styles.js";
import { AppContext } from "../../AppContext";
import profileImages from "../img/profileimage.js";

const CardValoracion = ({ valoraciones }) => {
  const { serverAddress } = useContext(AppContext);
  const navigation = useNavigation();

  const navigateToProfile = (usuOrigen) => {
    navigation.navigate("ProfileOther", { userId: usuOrigen });
  };

  // Mantenemos la lógica actual...
  const getComentarioYValoracion = (valoracion) => {
    if (valoracion.comentari && valoracion.valoracio !== undefined) {
      return {
        comentari: valoracion.comentari,
        valoracio: valoracion.valoracio,
      };
    } else if (
      Array.isArray(valoracion.comentaris) &&
      valoracion.comentaris.length > 0
    ) {
      const firstComentari = valoracion.comentaris[0];
      return {
        comentari: firstComentari.comentari || "Sin comentarios",
        valoracio: firstComentari.valoracio || null,
      };
    }
    return { comentari: "Sin comentarios", valoracio: null };
  };

  return (
    // Contenedor global que centra el contenido
    <View style={styles.container}>
      {(valoraciones || []).map((valoracion, index) => {
        const { comentari, valoracio } = getComentarioYValoracion(valoracion);

        // Lógica de fondo/borde
        let cardBorderStyle;
        let imageBorderStyle;
        let backgroundStyle;

        switch (valoracion.tipusUsuari) {
          case "Premium":
            cardBorderStyle = styles.cardBorderPremium;
            imageBorderStyle = styles.imageBorderPremium;
            backgroundStyle = styles.cardBackgroundPremium;
            break;
          case "Colaborador":
            cardBorderStyle = styles.cardBorderCollaborator;
            imageBorderStyle = styles.imageBorderCollaborator;
            backgroundStyle = styles.cardBackgroundCollaborator;
            break;
          default:
            cardBorderStyle = styles.cardBorderStandard;
            imageBorderStyle = styles.imageBorderStandard;
            backgroundStyle = styles.cardBackgroundStandard;
            break;
        }

        return (
          <TouchableOpacity
            key={index}
            onPress={() => navigateToProfile(valoracion.usu_Origen)}
          >
            <View
              style={[
                styles.cardContainer,
                cardBorderStyle,
                backgroundStyle,
              ]}
            >
              <Image
                style={[styles.profileImage, imageBorderStyle]}
                resizeMode="cover"
                source={
                  valoracion.foto
                    ? { uri: `${serverAddress}/fotos/${valoracion.foto}` }
                    : profileImages[valoracion.usu_Origen % 10] ||
                      require("../img/profiles/default.png")
                }
              />

              {valoracion.tipusUsuari === "Premium" && (
                <Text style={styles.proText}>PRO</Text>
              )}

              <View style={styles.textContainer}>
                <View style={styles.headerContainer}>
                  <Text
                    style={styles.name}
                  >{`${valoracion.nomUsuari} ${valoracion.cognomUsuari}`}</Text>
                  {valoracio !== null && (
                    <View style={styles.ratingContainer}>
                      <Text style={styles.rating}>{valoracio}</Text>
                      <Image
                        style={styles.ratingIcon}
                        resizeMode="cover"
                        source={require("../img/icons/puntuation.png")}
                      />
                    </View>
                  )}
                </View>
                <Text style={styles.comment} numberOfLines={4}>
                  {comentari}
                </Text>
              </View>
            </View>
          </TouchableOpacity>
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  // Este contenedor centrará las tarjetas en la pantalla
  container: {
    // Para web:
    width: "100%",             // Ojo, si tu contenedor padre ya define ancho
    alignItems: "center",      // Centra horizontalmente el contenido
    justifyContent: "center",  // Centra verticalmente si tiene altura suficiente
    // Si necesitas forzar un ancho máximo en web:
    // maxWidth: 800,
    // marginLeft: "auto",
    // marginRight: "auto",
  },

  cardContainer: {
    backgroundColor: Colors.grayLighter,
    width: 340,
    marginBottom: 16,
    marginTop: 12,
    borderRadius: 16,
    padding: 16,
    flexDirection: "row",
    alignItems: "center",
    borderWidth: 1,
    borderColor: Colors.grayMedium,
    height: 110,
    // Si quieres, en lugar de width fijo puedes usar un maxWidth
    // maxWidth: 400,
    // marginHorizontal: "auto", // También centrará en web
  },
  cardBorderStandard: {
    borderColor: Colors.grayMedium,
  },
  cardBorderPremium: {
    borderColor: Colors.secondary,
  },
  cardBorderCollaborator: {
    borderColor: Colors.primary,
  },
  cardBackgroundStandard: {
    backgroundColor: Colors.grayLighter,
  },
  cardBackgroundPremium: {
    backgroundColor: "rgba(255, 223, 158, 0.2)",
  },
  cardBackgroundCollaborator: {
    backgroundColor: "rgba(177, 217, 244, 0.2)",
  },
  profileImage: {
    borderRadius: 35.5,
    width: 71,
    height: 71,
    marginRight: 15,
    backgroundColor: Colors.primary,
    borderWidth: 2,
  },
  imageBorderStandard: {
    borderColor: Colors.grayMediumDark,
  },
  imageBorderPremium: {
    borderColor: Colors.secondary,
  },
  imageBorderCollaborator: {
    borderColor: Colors.primary,
  },
  proText: {
    position: "absolute",
    top: 4,
    left: 4,
    color: Colors.secondary,
    ...Fonts.poppinsSemiBold14,
  },
  textContainer: {
    flex: 1,
    justifyContent: "space-between",
  },
  headerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  name: {
    ...Fonts.dmSerifDisplayRegular16,
  },
  comment: {
    ...Fonts.poppinsRegular14,
  },
  ratingContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  rating: {
    ...Fonts.poppinsRegular14,
    marginRight: 5,
    marginBottom: -3,
  },
  ratingIcon: {
    width: 16,
    height: 14,
    marginLeft: 0,
  },
});

export default CardValoracion;
