import React, { useState, useContext, useRef } from "react";
import {
  StyleSheet,
  View,
  Text,
  Image,
  TextInput,
  TouchableOpacity,
  ScrollView,
  Dimensions,
  Platform,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import Svg, { Path } from "react-native-svg";
import * as SecureStore from "expo-secure-store"; // <-- Para almacenar el token en móvil (Expo o similar)

import { AppContext } from "../../AppContext";
import trocaliaIcon from "../img/icons/trocalia.png";
import { Colors, Fonts } from "../styles/styles";
import validateIcon from "../img/icons/validate.png";
import eyeIcon from "../img/icons/eye.png";

export default function Forgot() {
  const { width } = Dimensions.get("window");
  const svgWidth = width;
  const svgHeight = 180;

  const navigation = useNavigation();

  // Obtenemos funciones y estados globales del contexto:
  const {
    language,
    serverAddress,
    setToken,     // <--- para guardar el token igual que en Login
    setHasToken,  // <--- para avisar que hay token
  } = useContext(AppContext);

  // Estados para mostrar/ocultar la contraseña
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Estados para los errores de password y confirmPassword
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  // Estados para email y su error
  const [email, setEmail] = useState("");
  const [emailTimer, setEmailTimer] = useState(null);

  // Flujo de pasos
  const [step, setStep] = useState("email"); // 'email', 'verify', 'reset', 'completed'
  const [code, setCode] = useState("");

  // Estados para nueva contraseña y confirmación
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  // Control de errores de email
  const [emailError, setEmailError] = useState({
    message: "",
    style: styles.errorText,
  });

  // Timers de validación para password y confirmPassword
  const passwordTimerRef = useRef(null);
  const confirmPasswordTimerRef = useRef(null);

  // Traducciones:
  const translate = {
    CAT: {
      emailPlaceholder: "elteuemail@gmail.com",
      emailValidation: "Si us plau, introdueixi un correu electrònic vàlid.",
      recoveryButtonText: "Recupera el compte",
      rememberLoginTextPart1: "Ja recordes el compte? ",
      rememberLoginTextPart2: "Accedeix",
      emailFound: "Correu electrònic trobat",
      emailCheckError: "No existeix cap compte amb aquest correu electrònic.",
      code: "Codi",
      newPassword: "Nova Password",
      confirmPassword: "Confirmar-la",
      numcode: "Codi de 4 xifres",
      message:
        "Hem enviat un correu electrònic amb un codi de verificació. Si no el trobes, revisa la carpeta de correu brossa.",
      nomessage:
        "No s'ha pogut enviar el correu electrònic en aquests moments, torna-ho a provar més tard.",
      incorrectCode: "Codi incorrecte, et queden {0} intents.",
      correctAction: "Enhorabona, la contrasenya s'ha canviat correctament.",
      passwordPlaceholder: "Escriu la teva contrasenya",
      passwordValidation:
        "La contrasenya ha de contenir almenys una majúscula i com a mínim 6 caràcters.",
      repeatPasswordPlaceholder: "Repeteix la contrasenya",
      repeatPasswordValidation: "Les contrasenyes no coincideixen.",
      maxIntents: "S'ha arribat al màxim d'intents. Si us plau, torna a començar.",
    },
    ESP: {
      emailPlaceholder: "tuemail@gmail.com",
      emailValidation: "Por favor ingrese un correo electrónico válido.",
      recoveryButtonText: "Recuperar cuenta",
      rememberLoginTextPart1: "¿Ya recuerdas tu cuenta? ",
      rememberLoginTextPart2: "Acceder",
      emailFound: "Correo electrónico encontrado",
      emailCheckError: "No existe ninguna cuenta con este correo electrónico.",
      code: "Código",
      newPassword: "Nueva Password",
      confirmPassword: "Confirmarla",
      numcode: "Código de 4 cifras",
      message:
        "Hemos enviado un correo electrónico con un código de verificación. Si no lo encuentras, revisa la carpeta de correo no deseado.",
      nomessage:
        "No se ha podido enviar el correo electrónico en estos momentos, inténtelo de nuevo más tarde.",
      incorrectCode: "Código incorrecto, te quedan {0} intentos.",
      correctAction: "Enhorabuena, la contraseña se ha cambiado correctamente.",
      passwordPlaceholder: "Escribe tu contraseña",
      passwordValidation:
        "La contraseña debe contener al menos una mayúscula y al menos 6 caracteres.",
      repeatPasswordPlaceholder: "Repite la contraseña",
      repeatPasswordValidation: "Las contraseñas no coinciden.",
      maxIntents: "Se ha alcanzado el máximo de intentos. Por favor, vuelva a empezar.",
    },
    ENG: {
      emailPlaceholder: "youremail@gmail.com",
      emailValidation: "Please enter a valid email address.",
      recoveryButtonText: "Recover account",
      rememberLoginTextPart1: "Remember your account? ",
      rememberLoginTextPart2: "Login",
      emailFound: "Email found",
      emailCheckError: "There is no account with this email.",
      code: "Code",
      newPassword: "New Password",
      confirmPassword: "Confirm it",
      numcode: "4-digit code",
      message:
        "We have sent an email with a verification code. If you don't find it, check your spam folder.",
      nomessage:
        "The email could not be sent at this time, please try again later.",
      incorrectCode: "Incorrect code, {0} attempts left.",
      correctAction:
        "Congratulations, the password has been changed successfully.",
      passwordPlaceholder: "Enter your password",
      passwordValidation:
        "The password must contain at least one capital letter and at least 6 characters.",
      repeatPasswordPlaceholder: "Repeat the password",
      repeatPasswordValidation: "Passwords do not match.",
      maxIntents: "The maximum number of attempts has been reached. Please start over.",
    },
  };

  const displayMessage = (message, isError = true) => {
    setEmailError({
      message: message,
      style: isError ? styles.errorText : styles.successMessage,
    });
  };

  // VALIDACIÓN de email con un pequeño retardo
  const validateEmail = (text) => {
    setEmail(text);
    if (emailTimer) clearTimeout(emailTimer);

    if (text.match(/\.[a-zA-Z]{2,4}$/)) {
      setEmailTimer(
        setTimeout(() => {
          performEmailCheck(text);
        }, 300)
      );
    }
  };

  // Verifica si el email está bien formado
  const performEmailCheck = (text) => {
    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(text)) {
      setEmailError(translate[language].emailValidation);
    } else {
      checkEmail(text);
    }
  };

  // Verifica si existe la cuenta
  const checkEmail = async (email) => {
    try {
      const response = await fetch(`${serverAddress}/api/v1/auxiliars/email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ actuacio: "EM", email }),
      });
      const data = await response.json();
      if (data.status === "OK") {
        displayMessage(translate[language].emailFound, false); // Éxito
        return true;
      } else {
        displayMessage(translate[language].emailCheckError);
        return false;
      }
    } catch (error) {
      displayMessage(translate[language].emailCheckError);
      return false;
    }
  };

  // VALIDACIÓN de contraseña con retardo
  const validatePassword = (text) => {
    setNewPassword(text);

    if (passwordTimerRef.current) {
      clearTimeout(passwordTimerRef.current);
    }

    passwordTimerRef.current = setTimeout(() => {
      if (!/[A-Z]/.test(text) || text.length < 6) {
        setPasswordError(translate[language].passwordValidation);
      } else {
        setPasswordError("");
      }
    }, 600);
  };

  // VALIDACIÓN de confirmación con retardo
  const validateConfirmPassword = (text) => {
    setConfirmPassword(text);

    if (confirmPasswordTimerRef.current) {
      clearTimeout(confirmPasswordTimerRef.current);
    }

    confirmPasswordTimerRef.current = setTimeout(() => {
      if (text !== newPassword) {
        setConfirmPasswordError(translate[language].repeatPasswordValidation);
      } else {
        setConfirmPasswordError("");
      }
    }, 600);
  };

  // Envía el email de verificación
  const sendEmailVerification = async (email) => {
    try {
      const response = await fetch(
        `${serverAddress}/api/v1/auxiliars/actuacions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ actuacio: "RC", email }),
        }
      );
      const data = await response.json();
      if (data.data.status === "OK") {
        displayMessage(translate[language].message, false);
        setStep("verify");
      } else {
        displayMessage(translate[language].nomessage);
      }
    } catch (error) {
      displayMessage(translate[language].nomessage);
    }
  };

  // Verifica el código introducido
  const verifyCode = async () => {
    try {
      const response = await fetch(
        `${serverAddress}/api/v1/auxiliars/actuacions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            actuacio: "EC",
            email,
            codi: code,
          }),
        }
      );
      const data = await response.json();
      if (data.data.status === "OK") {
        setStep("reset");
      } else if (data.data.texte === "MAX_INTENTS") {
        displayMessage(translate[language].maxIntents, true);
        setStep("email");
        setCode("");
      } else {
        const attemptsLeft = 3 - data.data.intents;
        displayMessage(
          translate[language].incorrectCode.replace("{0}", attemptsLeft.toString())
        );
      }
    } catch (error) {
      setEmailError("Error al verificar el código.");
    }
  };

  // Cambia la contraseña
  const resetPassword = async () => {
    try {
      const response = await fetch(
        `${serverAddress}/api/v1/auxiliars/actuacions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            actuacio: "NC",
            email,
            codi: code,
            contraseña: newPassword,
          }),
        }
      );
      const data = await response.json();
      if (data.status === "OK" && data.data.affectedRows === 1) {
        displayMessage(translate[language].correctAction, false);
        setStep("completed");
      } else {
        displayMessage(translate[language].nomessage);
      }
    } catch (error) {
      displayMessage("Error al cambiar la contraseña.");
    }
  };

  // Maneja el flujo de steps
  const handleRecovery = async () => {
    if (step === "email") {
      const emailExists = await checkEmail(email);
      if (emailExists) {
        await sendEmailVerification(email);
      } else {
        setEmailError(translate[language].emailCheckError);
      }
    } else if (step === "verify") {
      await verifyCode();
    } else if (step === "reset") {
      await resetPassword();
    }
  };

  /**
   * INICIO DE SESIÓN AUTOMÁTICO
   * Cuando el usuario ha terminado de cambiar la contraseña (step === "completed"),
   * llamamos a esta función para loguear directamente.
   */
  const handleInstantLogin = async () => {
    try {
      // Hacemos login con el email y la nueva contraseña
      const response = await fetch(`${serverAddress}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email, 
          password: newPassword, // <-- la nueva contraseña que el usuario acaba de crear
        }),
      });

      const data = await response.json();

      if (data.respuesta === "NOK" || !data.token) {
        // Si falla el login, muestra un error
        // (puedes mostrar un alert, un displayMessage, etc.)
        displayMessage("Error al iniciar sesión automáticamente.");
      } else {
        // Almacenamos el token en SecureStore (o localStorage si es web)
        if (Platform.OS === "web") {
          localStorage.setItem("userToken", data.token);
        } else {
          await SecureStore.setItemAsync("userToken", data.token);
        }
        // Ponemos el token en el contexto
        setToken(data.token);
        setHasToken(true);

        // Navegamos directamente a Home (o la pantalla que quieras)
        navigation.navigate("Home");
      }
    } catch (error) {
      displayMessage("Error al iniciar sesión con la nueva contraseña.");
    }
  };

  // Render:
  return (
    <ScrollView contentContainerStyle={styles.container}>
      <View style={styles.blueHeader}>
        <Svg height={120} width={svgWidth}>
          <Path
            d={`M0,0 L${svgWidth},0 L${svgWidth},160 C${svgWidth},160 ${
              svgWidth / 2
            },200 0,160 Z`}
            fill={Colors.primary}
          />
        </Svg>
      </View>

      <View style={styles.formWrapper}>
        <View style={styles.logoContainer}>
          <Image style={styles.logo} resizeMode="cover" source={trocaliaIcon} />
        </View>

        {/* Paso Email */}
        {step === "email" && (
          <View style={styles.inputWrapper}>
            <TextInput
              style={[styles.inputBox, styles.inputBorder]}
              value={email}
              onChangeText={validateEmail}
              placeholder={translate[language].emailPlaceholder}
              autoCapitalize="none"
            />
            {emailError.message && (
              <Text style={emailError.style}>{emailError.message}</Text>
            )}
            <Text style={[styles.inputLabel, styles.labelText]}>Email</Text>
          </View>
        )}

        {/* Paso Verificación de Código */}
        {step === "verify" && (
          <>
            <View style={styles.inputWrapper}>
              <TextInput
                style={[styles.inputBox, styles.inputBorder]}
                value={code}
                onChangeText={setCode}
                placeholder={translate[language].numcode}
                autoCapitalize="none"
              />
              <Text style={[styles.inputLabel, styles.labelText]}>
                {translate[language].code}
              </Text>
            </View>
            {emailError.message && (
              <Text style={emailError.style}>{emailError.message}</Text>
            )}
          </>
        )}

        {/* Paso Reset de Contraseña */}
        {step === "reset" && (
          <>
            {/* Nueva Contraseña */}
            <View style={styles.inputWrapper}>
              <TextInput
                style={[styles.inputBox, styles.inputBorder]}
                value={newPassword}
                onChangeText={validatePassword}
                placeholder={translate[language].passwordPlaceholder}
                secureTextEntry={!showPassword}
              />
              <Text style={[styles.inputLabel, styles.labelText]}>
                {translate[language].newPassword}
              </Text>

              <TouchableOpacity
                style={styles.eyeTouchable}
                onPress={() => setShowPassword(!showPassword)}
                activeOpacity={0.6}
              >
                <Image source={eyeIcon} style={styles.eyeIcon} />
              </TouchableOpacity>

              {passwordError !== "" && (
                <Text style={styles.errorText}>{passwordError}</Text>
              )}
            </View>

            {/* Confirmar Nueva Contraseña */}
            <View style={styles.inputWrapper}>
              <TextInput
                style={[styles.inputBox, styles.inputBorder]}
                value={confirmPassword}
                onChangeText={validateConfirmPassword}
                placeholder={translate[language].repeatPasswordPlaceholder}
                secureTextEntry={!showConfirmPassword}
              />
              <Text style={[styles.inputLabel, styles.labelText]}>
                {translate[language].confirmPassword}
              </Text>

              <TouchableOpacity
                style={styles.eyeTouchable}
                onPress={() => setShowConfirmPassword(!showConfirmPassword)}
                activeOpacity={0.6}
              >
                <Image source={eyeIcon} style={styles.eyeIcon} />
              </TouchableOpacity>

              {confirmPasswordError !== "" && (
                <Text style={styles.errorText}>{confirmPasswordError}</Text>
              )}
            </View>
          </>
        )}

        <View style={styles.buttonWrapper}>
          {/* Paso Completado */}
          {step === "completed" ? (
            <>
              <Image source={validateIcon} style={styles.validateIcon} />
              <Text style={styles.successMessage}>
                {translate[language].correctAction}
              </Text>
              {/* Botón para autologuear directamente */}
              <TouchableOpacity
                style={[styles.buttonBox, styles.standardLayout]}
                onPress={handleInstantLogin}
              >
                <Text style={[styles.buttonText, styles.btnText]}>
                  {translate[language].rememberLoginTextPart2}
                </Text>
              </TouchableOpacity>
            </>
          ) : (
            <TouchableOpacity
              style={[styles.buttonBox, styles.standardLayout]}
              onPress={handleRecovery}
            >
              <Text style={[styles.buttonText, styles.btnText]}>
                {translate[language].recoveryButtonText}
              </Text>
            </TouchableOpacity>
          )}
        </View>
      </View>
    </ScrollView>
  );
}

// Estilos exactamente como tu código original
const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    backgroundColor: "#FFFFFF",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  blueHeader: {
    width: "100%",
    height: 100,
    backgroundColor: Colors.primary,
    zIndex: 0,
    borderBottomLeftRadius: 30,
    borderBottomRightRadius: 30,
    alignItems: "center",
  },
  formWrapper: {
    width: 315,
    marginTop: 0,
    alignItems: "center",
  },
  logoContainer: {
    marginTop: 20,
    alignItems: "center",
  },
  logo: {
    width: 240,
    height: 50,
    top: -95,
  },
  inputWrapper: {
    marginTop: 20,
  },
  inputBox: {
    width: 312,
    height: 49,
    borderColor: "rgba(0, 0, 0, 0.4)",
    borderRadius: 10,
    borderWidth: 1,
    backgroundColor: "rgba(255, 255, 255, 0.08)",
    paddingRight: 40, // Espacio para el icono de ojo
  },
  inputLabel: {
    fontFamily: Fonts.poppinsRegular14.fontFamily,
    color: "#695c5c",
    position: "absolute",
    top: -20,
    left: "50%",
    transform: [{ translateX: -25 }],
  },
  buttonWrapper: {
    marginTop: 20,
    alignItems: "center",
  },
  buttonBox: {
    borderRadius: 5,
    backgroundColor: Colors.primary,
    width: 312,
    height: 48,
    justifyContent: "center",
    alignItems: "center",
  },
  buttonText: {
    color: "rgba(255, 255, 255, 0.9)",
    fontFamily: Fonts.poppinsSemiBold16.fontFamily,
    fontSize: 16,
  },
  standardLayout: {
    width: 312,
    height: 48,
  },
  successMessage: {
    fontSize: 16,
    color: "green",
    marginBottom: 20,
  },
  validateIcon: {
    width: 50,
    height: 50,
    marginBottom: 60,
    alignItems: "center",
    marginTop: 10,
  },
  eyeTouchable: {
    position: "absolute",
    width: 40,
    height: 40,
    right: 5,
    top: 4,
    justifyContent: "center",
    alignItems: "center",
  },
  eyeIcon: {
    width: 20,
    height: 20,
  },
  errorText: {
    color: "red",
  },
  labelText: {
    fontFamily: Fonts.poppinsRegular14.fontFamily,
    height: 21,
    textAlign: "center",
    color: "#695c5c",
    position: "absolute",
    top: -20,
    left: "50%",
    transform: [{ translateX: 65 }],
  },
  successMessage2: {
    fontSize: 16,
    color: "green",
    marginBottom: 10,
    textAlign: "center",
  },
  inputBorder: {
    borderWidth: 1,
    borderStyle: "solid",
  },
});
