// Popup.js
import React, { useContext, useEffect, useRef } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Animated,
  Easing,
  Image,
  PanResponder,
  Dimensions,
} from "react-native";
import { AppContext } from "../../AppContext";
import { Colors, Fonts } from "../styles/styles";
import imageMap from "../db/imageMap"; // Asegúrate de la ruta correcta

const translations = {
  ESP: {
    proTitle: "Prueba Gratis Trocalia Pro",
    proMessage: "Destaca tu perfil y tus ofertas",
    colabTitle: "1€ Trocalia COLAB",
    colabMessage: "Tu soporte mantiene la comunidad",
    publicitariTitle: "Portal publicitario",
    publicitariMessage: "Desde 9,99€ Impulsa tu negocio",
    compartirTitle: "Invita a la Red Vecinal",
    compartirMessage: "¿Conoces a alguien que pueda sumar?",
    close: "Cerrar",
  },
  CAT: {
    proTitle: "Prova Gratis Trocalia Pro ",
    proMessage: "Destaca el teu perfil i ofertes",
    colabTitle: "1€ Trocalia COLAB",
    colabMessage: "El teu suport manté la comunitat",
    publicitariTitle: "Portal publicitari",
    publicitariMessage: "Des de 9,99€ Impulsa el teu negoci",
    compartirTitle: "Convida a la Xarxa Veïnal",
    compartirMessage: "Coneixes algú que pugui sumar?",
    close: "Tancar",
  },
  ENG: {
    proTitle: "Try Trocalia Pro Free",
    proMessage: "Highlight your profile and offers",
    colabTitle: "1€ Trocalia COLAB",
    colabMessage: "Your support keeps the community alive",
    publicitariTitle: "Advertising portal",
    publicitariMessage: "From 9,99€ Boost your business",
    compartirTitle: "Grow the Community",
    compartirMessage: "Know someone who could join?",
    close: "Close",
  },
};

const WINDOW_HEIGHT = Dimensions.get("window").height;
const MAX_EXPAND_HEIGHT = WINDOW_HEIGHT * 0.7;

const Popup = ({
  onClose,
  visible,
  popupType,
  onPressPro,
  onPressColab,
  onPressPublicitari,
  onPressCompartir,
  sendActionToServer,
}) => {
  const { language, userData } = useContext(AppContext);
  const t = translations[language];

  // slideAnim para mover el popup verticalmente (aparece desde +400px)
  const slideAnim = useRef(new Animated.Value(400)).current;
  // Forzamos opacidad a 1 (sin fade)
  const fadeAnim = useRef(new Animated.Value(1)).current;
  // Animación de “latido”
  const scaleAnim = useRef(new Animated.Value(1)).current;
  // panY para arrastrar hacia abajo
  const panY = useRef(new Animated.Value(0)).current;
  // heightAnim para expandir el popup al arrastrar hacia arriba
  const heightAnim = useRef(new Animated.Value(76)).current;

  const handleClose = () => {
    // Cierre: vuelve a 400px abajo (spring)
    Animated.spring(slideAnim, {
      toValue: 400,
      friction: 6,
      tension: 50,
      useNativeDriver: true,
    }).start(() => {
      onClose();
      // Envía acción al servidor si no es Guest
      const isUserGuest = userData?.data?.[0]?.tipusUsuari === "Guest";
      if (!isUserGuest) {
        try {
          sendActionToServer("HaTancatPopup");
        } catch {}
      }
    });

    // Reset de altura
    Animated.timing(heightAnim, {
      toValue: 76,
      duration: 500,
      easing: Easing.in(Easing.ease),
      useNativeDriver: false,
    }).start();
  };

  // PanResponder para el gesto de arrastre
  const panResponder = useRef(
    PanResponder.create({
      onStartShouldSetPanResponder: () => true,
      onPanResponderMove: (_, gestureState) => {
        if (gestureState.dy < 0) {
          // Arriba
          let newHeight = 76 - gestureState.dy;
          if (newHeight > MAX_EXPAND_HEIGHT) {
            newHeight = MAX_EXPAND_HEIGHT;
          }
          heightAnim.setValue(newHeight);
        } else {
          // Abajo
          heightAnim.setValue(76);
          panY.setValue(gestureState.dy);
        }
      },
      onPanResponderRelease: (_, gestureState) => {
        // Cambiado el umbral de 100 a 50 para que sea más fácil cerrar
        if (gestureState.dy > 50) {
          handleClose();
        } else {
          // Volver a la posición original
          Animated.spring(panY, {
            toValue: 0,
            friction: 5,
            useNativeDriver: true,
          }).start();

          Animated.spring(heightAnim, {
            toValue: 76,
            friction: 5,
            useNativeDriver: false,
          }).start();
        }
      },
    })
  ).current;

  useEffect(() => {
    if (visible) {
      // Reiniciar valores
      panY.setValue(0);
      heightAnim.setValue(76);
      slideAnim.setValue(400);
      fadeAnim.setValue(1);
      scaleAnim.setValue(1);

      // Esperamos 4s antes de mostrar
      const delayTimeout = setTimeout(() => {
        // Subir con spring (más lento, con rebote)
        Animated.spring(slideAnim, {
          toValue: 0,
          friction: 6, // Menos fricción → entra más rápido
          tension: 50,
          useNativeDriver: true,
        }).start();

        // Animación periódica “latido”
        const startPeriodicAnimations = () => {
          Animated.sequence([
            Animated.timing(scaleAnim, {
              toValue: 1.05,
              duration: 600,
              easing: Easing.out(Easing.ease),
              useNativeDriver: true,
            }),
            Animated.timing(scaleAnim, {
              toValue: 1,
              duration: 800,
              easing: Easing.out(Easing.ease),
              useNativeDriver: true,
            }),
          ]).start(() => {
            setTimeout(startPeriodicAnimations, 10000);
          });
        };
        const animationTimeout = setTimeout(startPeriodicAnimations, 2000);

        return () => clearTimeout(animationTimeout);
      }, 4000);

      return () => {
        clearTimeout(delayTimeout);
      };
    } else {
      // Reset si no visible
      slideAnim.setValue(400);
      fadeAnim.setValue(1);
      scaleAnim.setValue(1);
      heightAnim.setValue(76);
      panY.setValue(0);
    }
  }, [visible]);

  // Configuraciones del popup según tipo
  const popupConfig = {
    1: {
      title: t.compartirTitle,
      message: t.compartirMessage,
      icon: imageMap[107],
      backgroundColor: Colors.secondary,
      onPress: onPressCompartir,
      titleStyle: styles.compartirTitleText,
      messageStyle: styles.compartirMessageText,
    },
    2: {
      title: t.proTitle,
      message: t.proMessage,
      icon: imageMap[22],
      backgroundColor: Colors.secondary,
      onPress: onPressPro,
      titleStyle: styles.proTitleText,
      messageStyle: styles.proMessageText,
    },
    3: {
      title: t.colabTitle,
      message: t.colabMessage,
      icon: imageMap[179],
      backgroundColor: Colors.primary,
      onPress: onPressColab,
      titleStyle: styles.colabTitleText,
      messageStyle: styles.colabMessageText,
    },
    4: {
      title: t.publicitariTitle,
      message: t.publicitariMessage,
      icon: imageMap[240],
      backgroundColor: Colors.primary,
      onPress: onPressPublicitari,
      titleStyle: styles.publicitariTitleText,
      messageStyle: styles.publicitariMessageText,
    },
  };

  if (!visible || !popupConfig[popupType]) return null;

  const {
    title,
    message,
    icon,
    backgroundColor,
    onPress,
    titleStyle,
    messageStyle,
  } = popupConfig[popupType];
  const translateY = Animated.add(slideAnim, panY);

  return (
    <Animated.View
      style={[
        styles.outerContainer,
        {
          backgroundColor,
          transform: [{ translateY }, { scale: scaleAnim }],
        },
      ]}
      {...panResponder.panHandlers}
    >
      <Animated.View
        style={[
          styles.innerContainer,
          {
            height: heightAnim,
          },
        ]}
      >
        {/* Línea de agarre fina, más abajo */}
        <View style={styles.dragIndicator} />

        <TouchableOpacity onPress={onPress} style={styles.content}>
          <Image source={icon} style={styles.iconImage} />

          {/* Contenedor del texto: subimos la segunda línea 4px (marginTop negativo) */}
          <View style={[styles.textContainer, { marginTop: -4 }]}>
            <Text style={[styles.titleText, styles.centerTitle, titleStyle]}>
              {title}
            </Text>
            <Text style={[styles.messageText, messageStyle, styles.secondLine]}>
              {message}
            </Text>
          </View>
        </TouchableOpacity>
      </Animated.View>
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  outerContainer: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    borderTopLeftRadius: 80,
    borderTopRightRadius: 80,
    justifyContent: "flex-end",
    alignItems: "center",
    shadowColor: "#000",
    shadowOpacity: 0.1,
    shadowRadius: 6,
    elevation: 10,
    zIndex: 1000,
  },
  innerContainer: {
    width: "100%",
    borderTopLeftRadius: 80,
    borderTopRightRadius: 80,
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 10,
  },
  dragIndicator: {
    width: 50,
    height: 3,
    borderRadius: 2,
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    marginTop: 20,
    marginBottom: 10,
  },
  content: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 10,
  },
  iconImage: {
    width: 36,
    height: 36,
    marginRight: 10,
  },
  textContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
  titleText: {
    ...Fonts.poppinsSemiBold16,
    marginBottom: 3,
  },
  centerTitle: {
    textAlign: "center",
  },
  secondLine: {
    // Subir 4px adicional
    marginTop: -4,
  },
  proTitleText: {
    color: Colors.grayDark,
  },
  colabTitleText: {
    color: Colors.white,
  },
  publicitariTitleText: {
    color: Colors.white,
  },
  compartirTitleText: {
    color: Colors.grayDark,
  },
  messageText: {
    ...Fonts.poppinsRegular16,
    textAlign: "center",
  },
  proMessageText: {
    color: Colors.grayDark,
  },
  colabMessageText: {
    color: Colors.white,
  },
  publicitariMessageText: {
    color: Colors.white,
  },
  compartirMessageText: {
    color: Colors.grayDark,
  },
});

export default Popup;
