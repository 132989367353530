import React, { useState, useEffect, useContext, useRef } from "react";
import {
  View,
  Text,
  TextInput,
  StyleSheet,
  TouchableOpacity,
  Image,
  TouchableWithoutFeedback,
  KeyboardAvoidingView,
  Alert,
  Platform,
  Share,
  Animated,
} from "react-native";
import { Colors, Fonts } from "../styles/styles.js";
import { AppContext } from "../../AppContext";
import * as ImagePicker from "expo-image-picker";
import { SHARE_BASE_URL } from "../docs/config.js";
import { manipulateAsync, SaveFormat } from "expo-image-manipulator";
import { ToastContext } from "../../ToastContext";
import { DataContext } from "../../DataContext";
import { Picker as RNPicker } from "@react-native-picker/picker";

const translations = {
  ESP: {
    categoria: "Categoría",
    titulo: "Título",
    descripcion: "Descripción",
    palabraClave: "Palabra clave (separadas por comas)",
    precio: "Precio",
    modificar: "Modificar",
    errorTitulo: "El título es obligatorio.",
    errorDescripcion: "La descripción es obligatoria.",
    errorPalabraClave: "Las palabras clave son obligatorias.",
    permisosRequeridos:
      "Se requieren permisos para acceder a la cámara o la galería.",
    subirFoto: "Subir foto",
    camara: "Cámara",
    galeria: "Galería",
    cancelar: "Cancelar",
    venta: "Vendo",
    alquiler: "Alquilo",
    prestamo: "Presto",
    regalo: "Regalo",
    compro: "Compro",
    alquilo: "Alquilo",
    presto: "Prestado",
    regalado: "Regalado",
    modificaOferta: "Modifica tu oferta",
    modificaDemanda: "Modifica tu demanda",
    miraEstaOferta: "Hola!, Te comparto mi publicación 👍",
    compartir: "Comparte!",
    errorCompartir: "Error, no se pudo compartir. Inténtalo de nuevo.",
    tituloCompartir: "Compartir",
    telefono: "No puedes publicar un número de teléfono aquí",
    palabrasProhibidas:
      "Has usado alguna palabra prohibida, por favor, cámbiala",
    pausarTexto: "Para ",
    pausarNegrita: "pausar",
    eliminarNegrita: "eliminar",
    pausarFinal:
      ", desliza a la izquierda sobre la publicación de la oferta / demanda👇",
    pausaelimina: "Pausar/Eliminar",
    noneSelected: "Ninguna seleccionada",
    // Nuevas traducciones
    hourlyRate: "€/hora",
    toBeAgreed: "Pactar",
    monthlyRate: "€/mes",
    projectRate: "€/proyecto",
    numericFieldsOnly: "Sólo campos numéricos",
    priceplace: "Pon un precio",
    priceError: "El precio no es válido",
  },
  CAT: {
    categoria: "Categoria",
    titulo: "Títol",
    descripcion: "Descripció",
    palabraClave: "Paraula clau (separades per comes)",
    precio: "Preu",
    modificar: "Modifica",
    errorTitulo: "El títol és obligatori.",
    errorDescripcion: "La descripció és obligatòria.",
    errorPalabraClave: "Les paraules clau són obligatòries.",
    permisosRequeridos:
      "Es requereixen permisos per accedir a la càmera o la galeria.",
    subirFoto: "Puja foto",
    camara: "Càmera",
    galeria: "Galeria",
    cancelar: "Cancel·lar",
    venta: "Venc",
    alquiler: "Llogo",
    prestamo: "Presto",
    regalo: "Regalo",
    compro: "Compro",
    alquilo: "Llogo",
    presto: "Prestat",
    regalado: "Regalat",
    modificaOferta: "Modifica la teva oferta",
    modificaDemanda: "Modifica la teva demanda",
    miraEstaOferta: "Hola!, Et comparteixo la meva publicació 👍",
    compartir: "Comparteix!",
    errorCompartir: "Error, no s'ha pogut compartir. Torna-ho a provar.",
    tituloCompartir: "Compartir",
    telefono: "No pots publicar un número de telèfon aquí",
    palabrasProhibidas:
      "Has utilitzat alguna paraula prohibida, si us plau, canvia-la",
    pausarTexto: "Per ",
    pausarNegrita: "pausar",
    eliminarNegrita: "eliminar",
    pausarFinal:
      " llisca cap a l'esquerra sobre la publicació de l'oferta / demanda👇",
    pausaelimina: "Pausar/Eliminar",
    noneSelected: "Cap seleccionada",
    // Nuevas traducciones
    hourlyRate: "€/hora",
    toBeAgreed: "Pactar",
    monthlyRate: "€/mes",
    projectRate: "€/projecte",
    numericFieldsOnly: "Només camps numèrics",
    priceplace: "Posa un preu",
    priceError: "El preu no és vàlid",
  },
  ENG: {
    categoria: "Category",
    titulo: "Title",
    descripcion: "Description",
    palabraClave: "Keyword (separated by commas)",
    precio: "Price",
    modificar: "Modify",
    errorTitulo: "Title is mandatory.",
    errorDescripcion: "Description is mandatory.",
    errorPalabraClave: "Keywords are mandatory.",
    permisosRequeridos:
      "Permissions are required to access the camera or gallery.",
    subirFoto: "Upload photo",
    camara: "Camera",
    galeria: "Gallery",
    cancelar: "Cancel",
    venta: "Sell",
    alquiler: "Rent",
    prestamo: "Lend",
    regalo: "Gift",
    compro: "Buy",
    alquilo: "Rent",
    presto: "Borrowed",
    regalado: "Gifted",
    modificaOferta: "Modify your offer",
    modificaDemanda: "Modify your demand",
    miraEstaOferta: "Hi!, I share my post with you 👍",
    compartir: "Share!",
    errorCompartir: "Error, could not share. Try again.",
    tituloCompartir: "Share",
    telefono: "You can't post a phone number here",
    palabrasProhibidas: "You have used a forbidden word, please change it",
    pausarTexto: "To ",
    pausarNegrita: "pause",
    eliminarNegrita: "delete",
    pausarFinal: ", swipe left on the offer/demand publication👇",
    pausaelimina: "Pause/Delete",
    noneSelected: "None selected",
    // Nuevas traducciones
    hourlyRate: "€/hour",
    toBeAgreed: "To be agreed",
    monthlyRate: "€/month",
    projectRate: "€/project",
    numericFieldsOnly: "Numeric fields only",
    priceplace: "Set a price",
    priceError: "Invalid price",
  },
};

// 1) Lista unificada de frases iniciales que NO se pueden eliminar (título).
//    Asegúrate de incluir todas las variaciones que utilizas en tu aplicación.
const ALL_TITLE_PHRASES = [
  "Busco un servicio de",    // ESP largo
  "Busca un servicio de",    // ESP alternativo
  "Busco un servei",         // CAT largo
  "Busca un servei",         // CAT alternativo
  "Busco",                    // ESP/CAT corto
  "Busca",                    // ESP/CAT corto alternativo
  "I need a service of",      // ENG largo
  "I need",                   // ENG corto
];

// 2) Lista unificada de frases iniciales que NO se pueden eliminar (descripción).
//    Asegúrate de incluir todas las variaciones que utilizas en tu aplicación.
const ALL_DESCRIPTION_PHRASES = [
  "Necesito ayuda con",    // ESP
  "Busca ayuda con",       // ESP alternativo
  "Necessito ajuda amb",   // CAT
  "Busca ajuda amb",       // CAT alternativo
  "I need help with",      // ENG
  "Seek help with",        // ENG alternativo
];

// Función para eliminar emojis del texto
const removeEmojis = (text) => {
  return text.replace(
    /([\u2700-\u27BF]|[\uE000-\uF8FF]|[\uD83C-\uDBFF\uDC00-\uDFFF]|[\u2011-\u26FF])/g,
    ""
  );
};

// Función para capitalizar la primera letra y manejar texto completamente en mayúsculas
const capitalizeFirstLetter = (string) => {
  if (!string) return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const processField = (text) => {
  if (text && text === text.toUpperCase()) {
    return capitalizeFirstLetter(text.toLowerCase());
  }
  return capitalizeFirstLetter(text);
};

const ExpandingTextInput = ({ style, ...props }) => {
  const [height, setHeight] = useState(35); // Altura inicial mínima

  return (
    <TextInput
      {...props}
      multiline
      onContentSizeChange={(e) => {
        const contentHeight = e.nativeEvent.contentSize.height;
        setHeight(Math.max(35, contentHeight)); // Ajusta dinámicamente la altura
      }}
      style={[style, { height }]}
    />
  );
};

const Picker = ({ options, onValueChange, selectedValue }) => {
  const keys = Object.keys(options);
  const initialSelectedKey =
    keys.find((key) => key === selectedValue) || keys[0];
  const [selected, setSelected] = useState(initialSelectedKey);

  return (
    <View style={pickerStyles.container}>
      {Object.entries(options).map(([key, value]) => (
        <TouchableOpacity
          key={key}
          style={[
            pickerStyles.option,
            selected === key && pickerStyles.selectedOption,
          ]}
          onPress={() => {
            setSelected(key);
            onValueChange(key);
          }}
        >
          <Text
            style={
              selected === key
                ? pickerStyles.selectedText
                : pickerStyles.optionText
            }
          >
            {value}
          </Text>
        </TouchableOpacity>
      ))}
    </View>
  );
};

const EditModal = ({
  selectedItem,
  closeModal,
  userData,
  updateUser,
  updateOfferOrDemandList,
}) => {
  const { id, aplica_OD } = selectedItem;
  const { serverAddress, token, miID, language } = useContext(AppContext);
  const { showActionError } = useContext(ToastContext);
  const { restrictedWords } = useContext(DataContext);

  const [selectedImage, setSelectedImage] = useState(null);
  const [base64Image, setBase64Image] = useState(null);

  const [tituloError, setTituloError] = useState(false);
  const [descripcionError, setDescripcionError] = useState(false);
  const [palabrasClaveError, setPalabrasClaveError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isObjeto = selectedItem.esencia === "O";
  const isOffer = aplica_OD === "O";

  // Aquí guardamos la frase detectada en el título, si existe
  const [fixedPrefix, setFixedPrefix] = useState(null);
  // Aquí guardamos la frase detectada en la descripción, si existe
  const [fixedDescriptionPrefix, setFixedDescriptionPrefix] = useState(null);

  // Mostrar o no la imagen emergente (GIF de ayuda).
  const [showImage, setShowImage] = useState(false);
  const toggleImageVisibility = () => {
    setShowImage(!showImage);
  };

  const t = translations[language];

  // Opciones de transacción
  const options = isOffer
    ? {
        V: t.venta,
        L: t.alquiler,
        P: t.prestamo,
        G: t.regalo,
      }
    : {
        V: t.compro,
        L: t.alquilo,
        P: t.presto,
        G: t.regalado,
      };

  const ofertaOdemanda = isOffer
    ? userData.data[0].mevesOfertes.find((offer) => offer.id_Oferta === id)
    : userData.data[0].mevesDemandes.find((demand) => demand.id_demanda === id);

  const initialPickerOption = ofertaOdemanda
    ? ofertaOdemanda.tipoTransac || "V"
    : "V";
  const [pickerOption, setPickerOption] = useState(initialPickerOption);

  const [titulo, setTitulo] = useState(ofertaOdemanda?.titul || "");
  const [descripcion, setDescripcion] = useState(
    ofertaOdemanda?.descripcio || ""
  );
  const [palabrasClave, setPalabrasClave] = useState(
    ofertaOdemanda?.paraulesClau || ""
  );

  // Estados para el precio y la moneda
  const [precioHora, setPrecioHora] = useState("");
  const [currency, setCurrency] = useState("€");
  const [precioError, setPrecioError] = useState(false);

  // Inicializamos precioHora y currency según ofertaOdemanda?.preu
  useEffect(() => {
    const preu = ofertaOdemanda?.preu || "";
    if (preu) {
      if (preu.toLowerCase() === t.toBeAgreed.toLowerCase()) {
        setPrecioHora("");
        setCurrency(t.toBeAgreed);
      } else {
        const match = preu.match(/^([\d,\.]+)\s*(\S+)$/);
        if (match) {
          setPrecioHora(match[1]);
          setCurrency(match[2]);
        } else {
          setPrecioHora(preu);
          setCurrency("€");
        }
      }
    }
  }, [ofertaOdemanda?.preu, t.toBeAgreed]);

  /**
   * Detectamos si el título actual empieza con ALGUNA de las frases
   * unificadas en ALL_TITLE_PHRASES. Si es así, se fija en 'fixedPrefix'.
   */
  useEffect(() => {
    const matchedPrefix = ALL_TITLE_PHRASES.find((prefix) =>
      titulo.toLowerCase().startsWith(prefix.toLowerCase())
    );
    if (matchedPrefix) {
      setFixedPrefix(matchedPrefix);
    } else {
      setFixedPrefix(null);
    }
  }, [titulo]);

  /**
   * Detectamos si la descripción actual empieza con ALGUNA de las frases
   * unificadas en ALL_DESCRIPTION_PHRASES. Si es así, se fija en 'fixedDescriptionPrefix'.
   */
  useEffect(() => {
    const matchedDescription = ALL_DESCRIPTION_PHRASES.find((prefix) =>
      descripcion.toLowerCase().startsWith(prefix.toLowerCase())
    );
    if (matchedDescription) {
      setFixedDescriptionPrefix(matchedDescription);
    } else {
      setFixedDescriptionPrefix(null);
    }
  }, [descripcion]);

  // Solicita permisos de la galería (en móvil)
  const requestPermissions = async () => {
    if (Platform.OS !== "web") {
      const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
      if (status !== "granted") {
        alert(t.permisosRequeridos);
      }
    }
  };

  // Redimensionar y comprimir la imagen
  const resizeAndCompressImage = async (uri) => {
    const manipResult = await manipulateAsync(
      uri,
      [{ resize: { width: 500 } }],
      {
        compress: 0.5,
        format: SaveFormat.JPEG,
      }
    );
    return manipResult.uri;
  };

  // Maneja el resultado de la imagen escogida
  const handleImageResult = async (result) => {
    if (!result.canceled && result.assets) {
      const resizedImageUri = await resizeAndCompressImage(
        result.assets[0].uri
      );
      setSelectedImage({ uri: resizedImageUri });

      const imageData = await fetch(resizedImageUri);
      const blob = await imageData.blob();
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => setBase64Image(reader.result.split(",")[1]);
    }
  };

  const showImagePickerOptions = () => {
    if (Platform.OS === "web") {
      pickImage();
    } else {
      Alert.alert(t.subirFoto, t.subirFoto, [
        { text: t.camara, onPress: takePhoto },
        { text: t.galeria, onPress: pickImage },
        { text: t.cancelar, style: "cancel" },
      ]);
    }
  };

  const takePhoto = async () => {
    await requestPermissions();
    const result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 0.5,
    });
    handleImageResult(result);
  };

  const pickImage = async () => {
    await requestPermissions();
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 0.5,
    });
    handleImageResult(result);
  };

  const getOfferOrDemandText = () => {
    if (ofertaOdemanda) {
      if (ofertaOdemanda.id_demanda) {
        return t.modificaDemanda;
      } else if (ofertaOdemanda.id_Oferta) {
        return t.modificaOferta;
      }
    }
    return "";
  };

  const getCategoryDescription = () => {
    if (ofertaOdemanda) {
      if (ofertaOdemanda.categDesc && ofertaOdemanda.subcatDesc) {
        return `${ofertaOdemanda.categDesc}/${ofertaOdemanda.subcatDesc}`;
      } else if (ofertaOdemanda.subcatDesc) {
        return ofertaOdemanda.subcatDesc;
      }
    }
    return t.noneSelected;
  };

  const validateFields = () => {
    let isValid = true;

    // Validación para Título
    const tituloTrimmed = titulo.trim();
    const tituloLower = tituloTrimmed.toLowerCase();
    const isTituloEmpty = tituloTrimmed === "";
    const isTituloInitialPhrase = ALL_TITLE_PHRASES.some(
      (phrase) => phrase.toLowerCase() === tituloLower
    );

    if (isTituloEmpty || isTituloInitialPhrase) {
      setTituloError(true);
      isValid = false;
    } else {
      setTituloError(false);
    }

    // Validación para Descripción
    const descripcionTrimmed = descripcion.trim();
    const descripcionLower = descripcionTrimmed.toLowerCase();
    const isDescripcionEmpty = descripcionTrimmed === "";
    const isDescripcionInitialPhrase = ALL_DESCRIPTION_PHRASES.some(
      (phrase) => phrase.toLowerCase() === descripcionLower
    );

    if (isDescripcionEmpty || isDescripcionInitialPhrase) {
      setDescripcionError(true);
      isValid = false;
    } else {
      setDescripcionError(false);
    }

    // Validación para Palabras Clave
    if (palabrasClave.trim() === "") {
      setPalabrasClaveError(true);
      isValid = false;
    } else {
      setPalabrasClaveError(false);
    }

    return isValid;
  };

  /**
   * Función que maneja cambios en los campos
   * (título, descripción y palabras clave).
   */
  const handleInputChange = (text, fieldName) => {
    // Eliminamos saltos de línea al inicio y dobles saltos
    let cleanText = text;
    if (fieldName === "titulo" || fieldName === "descripcion") {
      cleanText = cleanText.replace(/\n{2,}/g, "\n");
      cleanText = cleanText.trimStart();
    }

    if (fieldName === "palabrasClave") {
      cleanText = removeEmojis(cleanText);
    }

    const textoOriginal = cleanText;

    // Normalizamos para comparar con palabras prohibidas
    const palabrasProhibidasNormalizadas = restrictedWords.map((p) =>
      p
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
    );
    const tieneSecuenciaNumericaProhibida = /(?:\D*\d){9,}/.test(cleanText);

    let textoSinProhibidas = textoOriginal;
    // Eliminamos palabras prohibidas
    palabrasProhibidasNormalizadas.forEach((palabraProhibida) => {
      const regex = new RegExp(`\\b${palabraProhibida}\\b`, "gi");
      textoSinProhibidas = textoSinProhibidas.replace(regex, "");
    });

    // Eliminamos posibles teléfonos
    if (tieneSecuenciaNumericaProhibida) {
      textoSinProhibidas = textoSinProhibidas.replace(/(?:\D*\d){9,}/g, "");
    }

    // Si se ha eliminado algo, alertamos
    if (textoSinProhibidas !== cleanText) {
      if (tieneSecuenciaNumericaProhibida) {
        Alert.alert(t.telefono);
      } else {
        Alert.alert(t.palabrasProhibidas);
      }
    }

    // Ahora gestionamos la protección del prefijo, según sea 'titulo' o 'descripcion'
    if (fieldName === "titulo") {
      if (fixedPrefix) {
        // Si ya hay un prefijo fijado:
        if (cleanText.toLowerCase().startsWith(fixedPrefix.toLowerCase())) {
          // Permitimos editar a partir de la frase
          const suffix = cleanText.substring(fixedPrefix.length);
          setTitulo(fixedPrefix + suffix);
        } else {
          // El usuario lo intentó borrar o modificar → lo restauramos
          setTitulo(fixedPrefix);
        }
      } else {
        // Si no hay un prefijo fijo, vemos si ahora empieza con alguna frase
        const matchedPrefix = ALL_TITLE_PHRASES.find((prefix) =>
          cleanText.toLowerCase().startsWith(prefix.toLowerCase())
        );
        if (matchedPrefix) {
          setFixedPrefix(matchedPrefix);
          const suffix = cleanText.substring(matchedPrefix.length);
          setTitulo(matchedPrefix + suffix);
        } else {
          // Ningún prefijo protegido
          setTitulo(cleanText);
        }
      }
    } else if (fieldName === "descripcion") {
      if (fixedDescriptionPrefix) {
        if (cleanText.toLowerCase().startsWith(fixedDescriptionPrefix.toLowerCase())) {
          const suffix = cleanText.substring(fixedDescriptionPrefix.length);
          setDescripcion(fixedDescriptionPrefix + suffix);
        } else {
          // El usuario trató de borrar/modificar la frase → restauramos
          setDescripcion(fixedDescriptionPrefix);
        }
      } else {
        // Detectamos si ha empezado a escribir una de las frases prohibidas
        const matchedDescription = ALL_DESCRIPTION_PHRASES.find((prefix) =>
          cleanText.toLowerCase().startsWith(prefix.toLowerCase())
        );
        if (matchedDescription) {
          setFixedDescriptionPrefix(matchedDescription);
          const suffix = cleanText.substring(matchedDescription.length);
          setDescripcion(matchedDescription + suffix);
        } else {
          setDescripcion(cleanText);
        }
      }
    } else if (fieldName === "palabrasClave") {
      // Guardamos directamente (ya sin prohibidas)
      setPalabrasClave(textoSinProhibidas);
    }
  };

  /**
   * Maneja cambios en el precio (campo numérico).
   */
  const handlePrecioChange = (text) => {
    if (/^\d+(\,\d{0,2})?$/.test(text) || text === "") {
      setPrecioError(false);
      setPrecioHora(text);
    } else {
      setPrecioError(true);
    }
  };

  const handleModifyButton = () => {
    if (isSubmitting) return;

    if (validateFields()) {
      setIsSubmitting(true);
      let palabrasClaveProcesadas = palabrasClave
        .split(",")
        .map((keyword) => {
          keyword = keyword.trim();
          if (!/^(\".*\"|\'.*\')$/.test(keyword)) {
            if (keyword.includes(" ")) {
              return `"${processField(keyword)}"`;
            } else {
              return processField(keyword);
            }
          }
          return processField(keyword);
        })
        .join(", ");

      if (palabrasClaveProcesadas.endsWith(", ")) {
        palabrasClaveProcesadas = palabrasClaveProcesadas.slice(0, -2);
      }

      sendToServer(palabrasClaveProcesadas);
    } else {
      // Opcional: Puedes añadir un Alert o alguna otra forma de notificar al usuario
      // que hay errores en el formulario.
      Alert.alert("Error", "Por favor, corrige los errores antes de continuar.");
    }
  };

  /**
   * Envía la modificación al servidor
   */
  const sendToServer = async (palabrasClaveProcesadas) => {
    const isOffer = selectedItem?.aplica_OD === "O";
    const tituloAjustado = processField(titulo);
    const descripcionAjustada = processField(descripcion);

    let tipoTransac = isObjeto ? pickerOption : selectedItem.tipoTransac;
    const esServicio = !isObjeto;

    let precioFinal;
    if (esServicio) {
      if (currency === t.toBeAgreed) {
        precioFinal = t.toBeAgreed;
      } else {
        precioFinal = precioHora + " " + currency;
      }
    } else {
      if (pickerOption === "G" || pickerOption === "P") {
        precioFinal = "null";
      } else {
        if (currency === t.toBeAgreed) {
          precioFinal = t.toBeAgreed;
        } else {
          precioFinal = precioHora + " " + currency;
        }
      }
    }

    const dataToSend = {
      id_oferta: isOffer ? selectedItem.id : undefined,
      id_demanda: isOffer ? undefined : selectedItem.id,
      titul: tituloAjustado,
      descripcio: descripcionAjustada,
      paraulesClau: palabrasClaveProcesadas,
      tipoTransac: tipoTransac,
      ...(base64Image ? { foto: base64Image } : {}),
      preu: precioFinal,
    };

    const endpoint = isOffer
      ? `${serverAddress}/api/v1/perfils/modioferta`
      : `${serverAddress}/api/v1/perfils/modidemanda`;

    try {
      let response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(dataToSend),
      });

      await response.json();
      if (response.ok) {
        closeModal();
        updateOfferOrDemandList();
      } else {
        showActionError();
      }
    } catch (error) {
      showActionError();
    } finally {
      setIsSubmitting(false);
    }
  };

  /**
   * Comparte la oferta/demanda
   */
  const handleShare = async () => {
    if (
      !ofertaOdemanda ||
      !ofertaOdemanda.titul ||
      !ofertaOdemanda.descripcio
    ) {
      Alert.alert(t.errorCompartir);
      return;
    }

    const urlToShare = `${SHARE_BASE_URL}/perfil/${miID}`;
    const shareMessage = `${t.miraEstaOferta}\n${ofertaOdemanda.titul}\n${ofertaOdemanda.descripcio}\n${urlToShare}`;

    try {
      await Share.share({
        message: shareMessage,
        title: t.tituloCompartir,
      });
    } catch (error) {
      Alert.alert(t.errorCompartir);
    }
  };

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : null}
      style={{ flex: 1 }}
    >
      <View style={styles.container}>
        {showImage && (
          <TouchableWithoutFeedback onPress={toggleImageVisibility}>
            <View style={styles.imageOverlay}>
              <Image
                source={require("../img/ajuda.gif")}
                style={styles.image}
                resizeMode="contain"
              />
            </View>
          </TouchableWithoutFeedback>
        )}

        <TouchableWithoutFeedback onPress={closeModal}>
          <View style={styles.backdrop} />
        </TouchableWithoutFeedback>

        <TouchableWithoutFeedback onPress={(e) => e.stopPropagation()}>
          <View style={styles.card}>
            <TouchableOpacity
              style={styles.closeButton}
              onPress={closeModal}
              hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
            >
              <Image
                source={require("../img/icons/block.png")}
                style={styles.closeImage}
              />
            </TouchableOpacity>

            <TouchableOpacity
              style={styles.shareButton}
              onPress={() => handleShare()}
            >
              <View style={styles.shareButtonContent}>
                <Image
                  source={require("../img/icons/share2.png")}
                  style={styles.shareIcon}
                />
                <Text style={styles.shareButtonText}>{t.compartir}</Text>
              </View>
            </TouchableOpacity>

            <View style={styles.separator2} />
            <Text
              style={[
                styles.categoryText,
                Fonts.poppinsMedium18,
                { marginBottom: 4, marginTop: 0 },
              ]}
            >
              {getOfferOrDemandText()}
            </Text>

            <Text style={styles.categoryText}>
              <Text style={[styles.fieldLabel, Fonts.poppinsMedium16]}>
                {t.categoria}:
              </Text>
              {getCategoryDescription()}
            </Text>

            {/* Si es objeto, mostramos el Picker de transacciones */}
            {isObjeto && (
              <Picker
                options={options}
                onValueChange={(value) => {
                  setPickerOption(value);
                }}
                selectedValue={pickerOption}
              />
            )}
            <View style={{ height: 20 }} />

            {/* Campo TÍTULO */}
            <View style={styles.inputWrapper}>
              <View style={styles.inputContainer}>
                <Text style={styles.fieldLabel}>{t.titulo}</Text>
                <ExpandingTextInput
                  style={styles.input}
                  maxLength={34}
                  placeholder={t.titulo}
                  value={titulo}
                  onChangeText={(text) => handleInputChange(text, "titulo")}
                />
              </View>
              {tituloError && (
                <Text style={{ color: "red" }}>{t.errorTitulo}</Text>
              )}
              <View style={styles.separator} />
            </View>

            {/* Campo DESCRIPCIÓN */}
            <View style={styles.inputWrapper}>
              <View style={styles.inputContainer}>
                <Text style={styles.fieldLabel}>{t.descripcion}</Text>
                <View style={{ height: 60, overflow: "hidden" }}>
                  <ExpandingTextInput
                    style={[styles.input, styles.descriptionInput]}
                    maxLength={200}
                    placeholder={t.descripcion}
                    value={descripcion}
                    onChangeText={(text) =>
                      handleInputChange(text, "descripcion")
                    }
                    multiline={true}
                  />
                </View>
              </View>
              {descripcionError && (
                <Text style={{ color: "red" }}>{t.errorDescripcion}</Text>
              )}
              <View style={styles.separator} />
            </View>

            {/* Campo PALABRAS CLAVE */}
            <View style={styles.inputWrapper}>
              <View style={styles.inputContainer}>
                <Text style={styles.fieldLabel}>{t.palabraClave}</Text>
                <ExpandingTextInput
                  style={styles.input}
                  maxLength={44}
                  placeholder={t.palabraClave}
                  value={palabrasClave}
                  onChangeText={(text) =>
                    handleInputChange(text, "palabrasClave")
                  }
                />
              </View>
              {palabrasClaveError && (
                <Text style={{ color: "red" }}>{t.errorPalabraClave}</Text>
              )}
              <View style={styles.separator} />
            </View>

            {/* Campo PRECIO (sólo si aplica) */}
            {((isObjeto && (pickerOption === "V" || pickerOption === "L")) ||
              !isObjeto) &&
            aplica_OD !== "D" ? (
              <View style={styles.inputWrapper}>
                <View style={styles.separator} />
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <View
                    style={[
                      styles.inputContainer,
                      { flex: 1.6, marginRight: 5 },
                    ]}
                  >
                    <Text style={styles.fieldLabel}>
                      {precioError ? t.numericFieldsOnly : t.precio}
                    </Text>
                    <TextInput
                      editable={currency !== t.toBeAgreed}
                      style={[
                        styles.input,
                        precioError
                          ? { borderColor: "red", borderWidth: 1 }
                          : {},
                      ]}
                      keyboardType="decimal-pad"
                      maxLength={8}
                      placeholder={t.priceplace}
                      value={precioHora}
                      onChangeText={handlePrecioChange}
                    />
                  </View>
                  <RNPicker
                    selectedValue={currency}
                    style={[
                      { height: 50, width: "45%" },
                      Platform.select({
                        ios: { marginTop: -180, itemSpace: 50 },
                        android: {},
                        web: { marginTop: -13 },
                      }),
                    ]}
                    itemStyle={{ fontSize: 12 }}
                    onValueChange={(itemValue) => {
                      setCurrency(itemValue);
                      if (itemValue === t.toBeAgreed) {
                        setPrecioHora("");
                      } else {
                        if (precioHora === t.toBeAgreed) {
                          setPrecioHora("");
                        }
                      }
                    }}
                  >
                    <RNPicker.Item label="€" value="€" />
                    <RNPicker.Item label={t.hourlyRate} value="€/h" />
                    <RNPicker.Item label={t.monthlyRate} value="€/mes" />
                    <RNPicker.Item label={t.projectRate} value="€/proj" />
                    <RNPicker.Item label={t.toBeAgreed} value={t.toBeAgreed} />
                  </RNPicker>
                </View>
                {precioError && (
                  <Text style={{ color: "red" }}>{t.priceError}</Text>
                )}
                <View style={styles.separator} />
              </View>
            ) : null}

            {/* Imagen (sólo si es objeto + oferta) */}
            {isObjeto && aplica_OD === "O" && (
              <TouchableOpacity onPress={showImagePickerOptions}>
                <Image
                  style={styles.offerImage}
                  source={
                    selectedImage
                      ? selectedImage
                      : selectedItem?.fotoOferta
                      ? {
                          uri: `${serverAddress}/fotos/${selectedItem.fotoOferta}`,
                        }
                      : require("../img/profiles/defaultcam.png")
                  }
                />
              </TouchableOpacity>
            )}

            {/* Botón "Modificar" */}
            <TouchableOpacity
              style={[
                styles.addButton,
                isSubmitting && { backgroundColor: Colors.grayLight },
              ]}
              onPress={handleModifyButton}
              disabled={isSubmitting}
            >
              <Text style={styles.addButtonLabel}>{t.modificar}</Text>
            </TouchableOpacity>

            {/* Info "Para pausar/eliminar" */}
            <Text style={styles.pauseText}>
              {t.pausarTexto}
              {t.pausarNegrita} / {t.eliminarNegrita}
              {t.pausarFinal}
            </Text>

            {/* Botón "Pausar/Eliminar" (GIF de ayuda) */}
            <TouchableOpacity
              style={styles.pauseDeleteButton}
              onPress={toggleImageVisibility}
            >
              <Text style={styles.pauseDeleteText}>{t.pausaelimina}</Text>
            </TouchableOpacity>
          </View>
        </TouchableWithoutFeedback>
      </View>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  backdrop: {
    ...StyleSheet.absoluteFill,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  card: {
    width: 340,
    backgroundColor: "#ffffff",
    borderRadius: 16,
    shadowColor: "#000000",
    shadowOpacity: 0.25,
    shadowRadius: 6,
    shadowOffset: { width: 1, height: 1 },
    padding: 20,
  },
  categoryText: {
    fontSize: 16,
    fontWeight: "400",
    color: "#000",
  },
  label: {
    fontWeight: "400",
  },
  fieldLabel: {
    color: Colors.primary,
    marginBottom: 0,
  },
  inputContainer: {
    backgroundColor: Colors.grayLighter,
    borderRadius: 6,
    padding: 4,
    marginBottom: 0,
  },
  input: {
    fontSize: 16,
    color: Colors.grayDark,
  },
  inputWrapper: {
    marginBottom: 0,
  },
  separator: {
    height: 8,
  },
  closeButton: {
    position: "absolute",
    top: -5,
    right: -5,
    padding: 10,
    zIndex: 9999,
    backgroundColor: "transparent",
  },
  closeImage: {
    width: 30,
    height: 30,
  },
  addButton: {
    alignItems: "center",
    alignSelf: "center",
    backgroundColor: "white",
    borderColor: Colors.primary,
    borderRadius: 20,
    borderWidth: 1,
    height: 35,
    justifyContent: "center",
    paddingHorizontal: 16,
    width: 194,
    marginTop: 4,
  },
  addButtonLabel: {
    fontSize: 16,
    color: Colors.primary,
  },
  offerImage: {
    width: 100,
    height: 100,
    alignSelf: "center",
    marginBottom: 20,
    borderRadius: 10,
  },
  shareButton: {
    alignItems: "center",
    alignSelf: "center",
    backgroundColor: Colors.primary,
    borderRadius: 20,
    height: 35,
    justifyContent: "center",
    paddingHorizontal: 16,
    width: "100%",
    marginTop: 5,
  },
  shareButtonContent: {
    flexDirection: "row",
    alignItems: "center",
  },
  shareIcon: {
    width: 24,
    height: 24,
    marginRight: 8,
  },
  shareButtonText: {
    color: "white",
    fontSize: 16,
  },
  pauseText: {
    fontSize: 12,
    color: Colors.grayDark,
    textAlign: "center",
    marginTop: 8,
  },
  separator2: {
    height: 1,
    backgroundColor: Colors.grayMedium,
    marginTop: 14,
    marginBottom: 8,
    width: "80%",
    alignSelf: "center",
  },
  imageOverlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9999,
  },
  image: {
    width: "80%",
    height: "80%",
  },
  pauseDeleteButton: {
    borderColor: Colors.grayMedium,
    borderWidth: 1,
    borderRadius: 5,
    paddingHorizontal: 15,
    paddingVertical: 5,
    backgroundColor: "#fff",
    alignSelf: "center",
    marginTop: 4,
  },
  pauseDeleteText: {
    color: Colors.grayDark,
    fontWeight: "bold",
    fontSize: 14,
    textAlign: "center",
  },
  descriptionInput: {
    textAlignVertical: "top",
    maxHeight: 60,
    overflow: "hidden",
  },
});

const pickerStyles = StyleSheet.create({
  container: {
    flexDirection: "row",
    height: 40,
    borderRadius: 20,
    borderWidth: 1,
    borderColor: Colors.grayMedium,
    backgroundColor: Colors.grayLighter,
    overflow: "hidden",
    marginBottom: 0,
    marginTop: 8,
  },
  option: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
    borderColor: Colors.grayMedium,
    borderRightWidth: 1,
  },
  selectedOption: {
    backgroundColor: Colors.primary,
  },
  optionText: {
    fontSize: 12,
    color: Colors.grayDark,
  },
  selectedText: {
    fontSize: 12,
    color: Colors.white,
  },
});

export default EditModal;
