import React, { useContext, useState, useRef, useEffect } from "react";
import {
  View,
  Text,
  StyleSheet,
  Image,
  TouchableOpacity,
  Modal,
  TextInput,
  TouchableWithoutFeedback,
  Keyboard,
  Platform,
  Alert,
  Linking,
} from "react-native";
import { AppContext } from "../../AppContext";
import { DataContext } from "../../DataContext.js";
import Ubication from "./Ubication";
import pinImage from "../img/icons/location.png";
import moreImage from "../img/icons/more.png";
import more2Image from "../img/icons/more2.png";
import puntuationImage from "../img/icons/puntuation.png";
import ProfileImage from "./ProfileImage";
import { Colors, Fonts } from "../styles/styles.js";
import { Dimensions } from "react-native";
import * as Haptic from "expo-haptics";
import { Share } from "react-native";
import { SHARE_BASE_URL } from "../docs/config.js";
import Pro from "../components/Pro"; // Asegúrate de que este es el path correcto del componente Pro
import * as Notifications from "expo-notifications";
import * as Device from "expo-device";

const infoImage = require("../img/icons/info.png");

const { height } =
  Platform.OS !== "web" ? Dimensions.get("window") : { height: 0 };
const smallDevice = Platform.OS !== "web" && height < 768;

const translations = {
  ESP: {
    loading: "Cargando...",
    presentationPlaceholder: "Escribe aquí tu presentación a la comunidad",
    valoracions: "valoraciones",
    telefono: "No puedes publicar un numero de telefono aqui",
    palabraProhibida: "Se ha eliminado la palabra prohibida",
    shareMessage: "Hola!, te paso mi perfil de Trocalia",
    apellido: "Apellido",
    enhorabuena: "Gracias por tu apoyo",
    proMessage: "¡Ya formas parte de Trocalia PRO!",
    explanation:
      "Este es tu perfil. Aquí te **presentas a la comunidad**. Recomendamos que subas una **foto** y **presentación**. \n\nAdemás puedes publicar qué es lo que **ofreces** (qué sabes hacer o tienes) y qué es lo que **demandas** (qué deseas o necesitas de la comunidad).",
    close: "Cerrar",
    enableNotifications: "Activar notificaciones",
    pushPermissionTitle: "Falta un paso",
    pushPermissionDenied:
      "Debes habilitar los permisos de notificación del dispositivo, ¡te llevamos allí!",
    pushTokenError: "Error al activar las notificaciones push.",
    webPushMessage: "Para activar notificaciones push debes descargar la App.",
    pushios:
      "Para activar las Notificaciones descarga nuestra aplicación desde la App Store",
    pushandroid:
      "Para activar las Notificaciones descarga nuestra aplicación desde Google Play",
    exito: "Éxito",
    successMessage: "Ya tienes las notificaciones perfectamente configuradas.",
    nombreVacioError: "El nombre no puede estar vacío",
    /* Nuevo mensaje de error corto */
    nombreInvalidoError: "Se ha detectado un error en su nombre.",
  },
  CAT: {
    loading: "Carregant...",
    presentationPlaceholder: "Escriu aquí la teva presentació a la comunitat",
    valoracions: "valoracions",
    telefono: "No pots publicar un numero de telèfon aquí",
    palabraProhibida: "S'ha eliminat la paraula prohibida",
    shareMessage: "Hola!, et passo el meu perfil de Trocalia",
    apellido: "Cognom",
    enhorabuena: "Gràcies pel teu recolzament",
    proMessage: "Ja formes part de Trocalia PRO!",
    explanation:
      "Aquest és el teu perfil. Aquí et **presentes a la comunitat**. Recomanem que pugis una **foto** i **presentació**. \n\nA més pots publicar què és el que **ofereixes** (què saps fer o tens) i què és el que **demandes** (què desitges o necessites de la comunitat).",
    close: "Tancar",
    enableNotifications: "Activar notificacions",
    pushPermissionTitle: "Falta un pas",
    pushPermissionDenied:
      "Has d'habilitar els permisos de notificació del dispositiu, t'hi portem!",
    pushTokenError: "Error al activar les notificacions push.",
    webPushMessage: "Per activar notificacions push has de descarregar l'App.",
    pushios:
      "Per activar les Notificacions descarrega la nostra aplicació des de l'App Store",
    pushandroid:
      "Per activar les Notificacions descarrega la nostra aplicació des de Google Play",
    exito: "Èxit",
    successMessage: "Ja tens les notificacions perfectament configurades.",
    nombreVacioError: "El nom no pot estar buit",
    nombreInvalidoError: "S'ha detectat un error en el nom.",
  },
  ENG: {
    loading: "Loading...",
    presentationPlaceholder: "Write your presentation to the community here",
    valoracions: "ratings",
    telefono: "You can't post a phone number here",
    palabraProhibida: "The prohibited word has been removed",
    shareMessage: "Hi!, here is my Trocalia profile",
    apellido: "Last name",
    enhorabuena: "Thank you for your support",
    proMessage: "You are now part of Trocalia PRO!",
    explanation:
      "This is your profile. Here you introduce yourself to the community. We recommend you upload a **photo** and **presentation**. \n\nAdditionally, you can post what you **offer** (what you can do or have) and what you **need** (what you desire or need from the community).",
    close: "Close",
    enableNotifications: "Enable notifications",
    pushPermissionTitle: "One more step",
    pushPermissionDenied:
      "You need to enable the device's notification permissions, let us take you there!",
    pushTokenError: "Error enabling push notifications.",
    webPushMessage: "To enable push notifications you must download the App.",
    pushios: "To enable Notifications download our app from the App Store",
    pushandroid: "To enable Notifications download our app from Google Play",
    exito: "Success",
    successMessage: "You have successfully configured notifications.",
    nombreVacioError: "The name can't be empty",
    nombreInvalidoError: "An error was detected in your name.",
  },
};

const ExpandingTextInput = React.forwardRef(
  ({ style, multiline, ...props }, ref) => {
    const [height, setHeight] = useState(0);

    const handleContentSizeChange = (event) => {
      setHeight(event.nativeEvent.contentSize.height);
    };

    const combinedStyles = [
      style,
      Platform.OS !== "web" && { flexGrow: 1 },
      Platform.OS === "web" && { height: Math.max(35, height) },
      smallDevice && { maxHeight: 80 },
    ];

    return (
      <TextInput
        ref={ref}
        {...props}
        style={combinedStyles}
        multiline={multiline}
        onContentSizeChange={multiline ? handleContentSizeChange : null}
        {...(Platform.OS === "web" ? { numberOfLines: 1 } : {})}
      />
    );
  }
);

const ProfileInfo = ({
  onMoreIconPress,
  openLocationModalOnInit,
  showOptions,
}) => {
  const { userData, language, serverAddress, token, updateUser, miID } =
    useContext(AppContext);
  const { restrictedWords } = useContext(DataContext);
  const t = translations[language];

  if (!userData || !userData.data || userData.data.length === 0) {
    return <Text>{t.loading}</Text>;
  }

  const [showEnableNotificationsButton, setShowEnableNotificationsButton] =
    useState(false);

  const registerForPushNotificationsAsync = async () => {
    if (!Device.isDevice) {
      return;
    }

    if (Platform.OS === "web") {
      const isIOS =
        /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      const isAndroid = /Android/.test(navigator.userAgent);

      if (isIOS) {
        window.open("https://apps.apple.com/es/app/trocalia/id6478570314");
      } else if (isAndroid) {
        window.open(
          "https://play.google.com/store/apps/details?id=com.trocalia"
        );
      }
      return;
    }

    const { status: existingStatus } = await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;

    if (existingStatus !== "granted") {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }

    if (finalStatus !== "granted") {
      openSettings();
      return;
    }

    try {
      const projectId = "097c2eab-348a-4f63-bcf5-de3978115ad3";
      const pushToken = (
        await Notifications.getExpoPushTokenAsync({ projectId })
      ).data;
      sendPushTokenToServer(pushToken);
    } catch (error) {
      //console.error("Error al obtener el token push:", error);
    }
  };

  const sendPushTokenToServer = async (pushToken) => {
    if (!token) return;

    const url = `${serverAddress}/api/v1/perfils/actuacions`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          actuacio: "GTP",
          expoPushToken: pushToken,
        }),
      });

      if (response.ok) {
        Alert.alert(t.exito, t.successMessage);
      } else {
        throw new Error(translations[language].apiError);
      }
    } catch (error) {
      Alert.alert(
        "Error",
        translations[language].apiError + " " + error.message
      );
    }
  };

  const openSettings = () => {
    if (Platform.OS === "ios") {
      Linking.openURL("app-settings:");
    } else {
      Linking.openSettings();
    }
  };

  const checkPushNotificationPermissions = async () => {
    if (!Device.isDevice) {
      setShowEnableNotificationsButton(true);
      return;
    }

    const { status } = await Notifications.getPermissionsAsync();
    if (status !== "granted") {
      setShowEnableNotificationsButton(true);
    } else {
      setShowEnableNotificationsButton(false);
    }
  };

  useEffect(() => {
    checkPushNotificationPermissions();
  }, []);

  const processRating = (rating) => {
    if (rating === null || rating === undefined || isNaN(rating)) {
      return "-";
    }
    return Number(rating).toFixed(1);
  };

  const processCommentCount = (count) => {
    if (count === null || count === undefined || isNaN(count)) {
      return "0";
    }
    return count.toString();
  };

  const [modalVisible, setModalVisible] = useState(false);
  const [infoModalVisible, setInfoModalVisible] = useState(false);
  const [proModalVisible, setProModalVisible] = useState(false);

  const [presentationText, setPresentationText] = useState(
    userData.data[0].presentacio || t.presentationPlaceholder
  );
  const [isEditing, setIsEditing] = useState(false);
  const [charCount, setCharCount] = useState(presentationText.length);
  const textInputRef = useRef(null);
  const [showingOptions, setShowingOptions] = useState(false);

  // Guardamos el valor original del nombre para revertir si es inválido
  const oldFirstName = userData.data[0].nomUsuari;

  const [editingFirstName, setEditingFirstName] = useState(false);
  const [editingLastName, setEditingLastName] = useState(false);

  const [firstName, setFirstName] = useState(oldFirstName);
  const [lastName, setLastName] = useState(userData.data[0].cognomUsuari || "");
  const [isFocused, setIsFocused] = useState(false);

  const handlePress = () => {
    setShowingOptions(!showingOptions);
    onMoreIconPress();
  };

  const getMoreIcon = () => {
    return showOptions ? more2Image : moreImage;
  };

  const showLocationModal = () => setModalVisible(true);
  const hideLocationModal = () => setModalVisible(false);

  const showInfoModal = () => setInfoModalVisible(true);
  const hideInfoModal = () => setInfoModalVisible(false);

  useEffect(() => {
    if (openLocationModalOnInit) {
      showLocationModal();
    }
  }, [openLocationModalOnInit]);

  const TextInputComponent =
    Platform.OS === "web" ? TextInput : ExpandingTextInput;

  const handleFocus = () => {
    if (!isEditing && !userData.data[0].presentacio) {
      setPresentationText("");
      setIsEditing(true);
    }
  };

  const handleDoneEditing = () => {
    if (textInputRef.current) {
      textInputRef.current.blur();
    }
    sendPresentationToServer();
  };

  const encuentraPalabrasProhibidas = (texto) => {
    const textoConEspacio = texto + " ";

    for (let word of restrictedWords) {
      if (new RegExp("\\b" + word + "\\b", "i").test(textoConEspacio)) {
        return { tipo: "palabra", valor: word };
      }
    }
    const tieneSecuenciaNumericaProhibida =
      /(?<!\d)(\d[\s.,\/-]*){9,}(?!\d)/.test(texto);

    if (tieneSecuenciaNumericaProhibida) {
      const telefonoEncontrado = texto.match(/(?<!\d)(\d[\s.,\/-]*){9,}(?!\d)/);
      if (telefonoEncontrado && telefonoEncontrado.length > 0) {
        return { tipo: "telefono", valor: telefonoEncontrado[0] };
      }
    }
    return null;
  };

  const handleTextInputChange = (text) => {
    if (text.length <= maxCharLimit) {
      const resultado = encuentraPalabrasProhibidas(text);
      if (resultado) {
        let mensajeAlerta;
        if (resultado.tipo === "telefono") {
          mensajeAlerta = `${t.telefono}: ${resultado.valor}`;
        } else {
          mensajeAlerta = `${t.palabraProhibida}: ${resultado.valor}`;
        }
        alert(mensajeAlerta);

        const regex = new RegExp(
          resultado.valor.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"),
          "gi"
        );
        const textoLimpio = text.replace(regex, "").trim();

        setPresentationText(textoLimpio);
        setCharCount(textoLimpio.length);
      } else {
        setPresentationText(text);
        setCharCount(text.length);
      }
    }
  };

  const handleShare = async () => {
    const urlToShare = `${SHARE_BASE_URL}/perfil/${miID}`;
    const shareMessage = `${t.shareMessage} ${urlToShare}`;

    if (Platform.OS === "web") {
      if (navigator.share) {
        try {
          await navigator.share({
            title: "Compartir perfil",
            text: shareMessage,
            url: urlToShare,
          });
        } catch (error) {
          Alert.alert("Error", "No se pudo compartir. Inténtalo de nuevo.");
        }
      } else {
        Alert.alert(
          "Error",
          "La funcionalidad de compartir no está disponible en este navegador."
        );
      }
    } else {
      try {
        await Share.share({
          message: shareMessage,
          title: "Compartir perfil",
        });
      } catch (error) {
        Alert.alert("Error", "No se pudo compartir. Inténtalo de nuevo.");
      }
    }
  };

  /**
   * isValidFirstName: letras (con acentos), espacios, . y -,
   * con al menos una letra en total
   */
  const isValidFirstName = (name) => {
    // Permitir: letras (incluyendo acentos), espacios, punto, guion.
    const regexSoloPermitidos = /^[a-zA-ZÁÉÍÓÚáéíóúÀ-ÿ.\-&\s]+$/;
    // Debe tener al menos una letra en cualquier parte
    const regexTieneLetra = /[a-zA-ZÁÉÍÓÚáéíóúÀ-ÿ]/;

    return regexSoloPermitidos.test(name) && regexTieneLetra.test(name);
  };

  const sendNameToServer = async (field) => {
    try {
      await fetch(`${serverAddress}/api/v1/perfils/actuacions`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          actuacio: "GNA",
          nomUsuari: firstName,
          cognomUsuari: lastName,
        }),
      });
    } catch (error) {
      // console.error("Error al enviar el nombre:", error);
    }
  };

  /**
   * handleFirstNameBlur:
   * - Si está vacío: revertir + alert (nombreVacioError).
   * - Si no pasa isValidFirstName: revertir + alert (nombreInvalidoError).
   * - Caso contrario: guardar.
   */
  const handleFirstNameBlur = () => {
    const trimmed = firstName.trim();
    if (trimmed.length === 0) {
      Alert.alert("Error", t.nombreVacioError);
      setFirstName(oldFirstName);
      setEditingFirstName(false);
      return;
    }
    if (!isValidFirstName(trimmed)) {
      Alert.alert("Error", t.nombreInvalidoError);
      setFirstName(oldFirstName);
      setEditingFirstName(false);
      return;
    }
    // Si llega aquí, es válido
    setEditingFirstName(false);
    sendNameToServer("firstName");
  };

  // Dejamos la lógica del apellido completamente igual.
  const handleLastNameBlur = () => {
    setEditingLastName(false);
    sendNameToServer("lastName");
  };

  const handleNameChange = (type, value) => {
    // Mantenemos la lógica de tu código original (sólo toco firstName).
    if (type === "firstName") {
      if (value.length <= 10) {
        setFirstName(value);
      }
    } else if (type === "lastName" && value.length <= 10) {
      setLastName(value);
    }
  };

  const sendPresentationToServer = async () => {
    const palabrasProhibidasEncontradas =
      encuentraPalabrasProhibidas(presentationText);
    if (palabrasProhibidasEncontradas) {
      return;
    }
    try {
      const response = await fetch(
        `${serverAddress}/api/v1/perfils/actuacions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            actuacio: "GP",
            presentacio: presentationText,
          }),
        }
      );
      if (response.ok) {
        updateUser((prevUserData) => ({
          ...prevUserData,
          data: [{ ...prevUserData.data[0], presentacio: presentationText }],
        }));
      }
    } catch (error) {
      //console.error("Error al enviar la presentación:", error);
    }
  };

  const handleProPress = async () => {
    try {
      if (Platform.OS !== "web") {
        await Haptic.impactAsync(Haptic.ImpactFeedbackStyle.Heavy);
      }
    } catch (error) {
      //console.log("Haptic feedback no está disponible", error);
    }

    if (userData.data[0].tipusUsuari === "Premium") {
      Alert.alert(t.enhorabuena, t.proMessage);
    } else {
      setProModalVisible(true);
    }
  };

  const proTextStyle =
    userData.data[0].tipusUsuari === "Premium"
      ? styles.proTextPremium
      : styles.proTextNonPremium;

  const maxCharLimit = 140;

  return (
    <View style={styles.container}>
      <Modal
        animationType="slide"
        transparent={false}
        visible={modalVisible}
        onRequestClose={hideLocationModal}
      >
        <Ubication hideModal={hideLocationModal} />
      </Modal>

      <Modal
        animationType="slide"
        transparent={true}
        visible={proModalVisible}
        onRequestClose={() => setProModalVisible(false)}
      >
        <TouchableOpacity
          style={{ flex: 1 }}
          onPress={() => setProModalVisible(false)}
          activeOpacity={1}
        >
          <Pro closeModal={() => setProModalVisible(false)} />
        </TouchableOpacity>
      </Modal>

      <Modal
        animationType="slide"
        transparent={true}
        visible={infoModalVisible}
        onRequestClose={hideInfoModal}
      >
        <TouchableWithoutFeedback onPress={hideInfoModal}>
          <View style={styles.modalOverlay}>
            <View style={styles.modalContainer}>
              <View style={styles.modalView}>
                <Text style={styles.modalText}>
                  {t.explanation
                    .split("**")
                    .map((part, index) =>
                      index % 2 === 1 ? (
                        <Text key={index} style={styles.boldText}>
                          {part}
                        </Text>
                      ) : (
                        part
                      )
                    )}
                </Text>
                {showEnableNotificationsButton ? (
                  <View style={styles.buttonContainer}>
                    <TouchableOpacity
                      style={[styles.button, styles.buttonClose]}
                      onPress={hideInfoModal}
                    >
                      <Text style={[styles.textStyle, styles.textClose]}>
                        {t.close}
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={[styles.button, styles.buttonEnable]}
                      onPress={registerForPushNotificationsAsync}
                    >
                      <Text style={[styles.textStyle, styles.textEnable]}>
                        {t.enableNotifications}
                      </Text>
                    </TouchableOpacity>
                  </View>
                ) : (
                  <View style={styles.singleButtonContainer}>
                    <TouchableOpacity
                      style={[styles.singleButton, styles.buttonClose]}
                      onPress={hideInfoModal}
                    >
                      <Text style={[styles.textStyle, styles.textClose]}>
                        {t.close}
                      </Text>
                    </TouchableOpacity>
                  </View>
                )}
              </View>
            </View>
          </View>
        </TouchableWithoutFeedback>
      </Modal>

      {Platform.OS !== "web" ? (
        <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
          <View style={styles.profileCard}>
            <View style={styles.profileImageContainer}>
              <ProfileImage />
            </View>

            {/* NOMBRE (Con validación especial) */}
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              {editingFirstName ? (
                <TextInput
                  value={firstName}
                  onChangeText={(text) => handleNameChange("firstName", text)}
                  onBlur={handleFirstNameBlur}
                  autoFocus
                  style={styles.profileName}
                  maxLength={10}
                />
              ) : (
                <TouchableOpacity onPress={() => setEditingFirstName(true)}>
                  <Text style={styles.profileName}>{firstName}</Text>
                </TouchableOpacity>
              )}

              <Text style={{ marginHorizontal: 5 }}> </Text>
              {/* APELLIDO: sin cambios */}
              {editingLastName ? (
                <TextInput
                  value={lastName}
                  onChangeText={setLastName}
                  onBlur={handleLastNameBlur}
                  autoFocus
                  style={styles.profileName}
                  maxLength={10}
                  placeholder={t.apellido}
                  placeholderTextColor={Colors.grayMedium}
                />
              ) : (
                <TouchableOpacity onPress={() => setEditingLastName(true)}>
                  <Text
                    style={[
                      styles.profileName,
                      !lastName ? { color: Colors.grayMedium } : {},
                    ]}
                  >
                    {lastName || t.apellido}
                  </Text>
                </TouchableOpacity>
              )}
            </View>

            <TouchableOpacity
              onPress={showLocationModal}
              style={styles.locationTouchArea}
            >
              <View style={styles.locationContainer}>
                <Image source={pinImage} style={styles.locationIcon} />
                <Text style={styles.profileLocationName}>
                  {userData.data[0].zona}
                </Text>
              </View>
            </TouchableOpacity>

            <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
              <TouchableOpacity
                onPress={async () => {
                  try {
                    if (Platform.OS === "ios") {
                      await Haptic.impactAsync(
                        Haptic.ImpactFeedbackStyle.Heavy
                      );
                    }
                  } catch (error) {
                    //console.log(error);
                  }
                  handlePress();
                }}
                hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
              >
                <Image source={getMoreIcon()} style={styles.moreIcon} />
              </TouchableOpacity>
            </TouchableWithoutFeedback>

            <TouchableOpacity
              onPress={async () => {
                try {
                  if (Platform.OS !== "web") {
                    await Haptic.impactAsync(Haptic.ImpactFeedbackStyle.Heavy);
                  }
                } catch (error) {
                  //console.log(error);
                }
                handleShare();
              }}
              hitSlop={{ top: 5, bottom: 5, left: 5, right: 5 }}
            >
              <Image
                source={require("../img/icons/share.png")}
                style={styles.shareIcon}
              />
            </TouchableOpacity>

            <Text style={styles.ratingText}>
              {processRating(userData.data[0].valoracio)}
            </Text>
            <Text style={styles.reviewCount}>
              {`${processCommentCount(userData.data[0].numComentaris)} ${
                t.valoracions
              }`}
            </Text>

            <View style={styles.ratingSeparator} />
            <Image source={puntuationImage} style={styles.ratingIcon} />

            <View style={styles.presentationContainer}>
              <TextInputComponent
                ref={textInputRef}
                style={styles.presentationText}
                value={presentationText}
                onChangeText={handleTextInputChange}
                multiline
                numberOfLines={Platform.OS === "web" ? 7 : undefined}
                maxLength={maxCharLimit}
                returnKeyType="done"
                blurOnSubmit={true}
                onFocus={() => {
                  setIsFocused(true);
                  handleFocus();
                }}
                onSubmitEditing={handleDoneEditing}
                onBlur={() => {
                  setIsFocused(false);
                  handleDoneEditing();
                }}
                textAlignVertical="top"
              />
              {isFocused && (
                <Text style={styles.charCountText}>
                  {charCount}/{maxCharLimit}
                </Text>
              )}
            </View>

            <TouchableOpacity
              style={styles.proLabelContainer}
              onPress={handleProPress}
            >
              <Text style={proTextStyle}>PRO</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.iconTouchableArea}
              onPress={showInfoModal}
            >
              <Image source={infoImage} style={styles.infoIcon} />
            </TouchableOpacity>
          </View>
        </TouchableWithoutFeedback>
      ) : (
        // Versión web
        <View style={styles.profileCard}>
          <View style={styles.profileImageContainer}>
            <ProfileImage />
          </View>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            {editingFirstName ? (
              <ExpandingTextInput
                value={firstName}
                onChangeText={(text) => handleNameChange("firstName", text)}
                onBlur={handleFirstNameBlur}
                autoFocus
                style={styles.profileName}
                maxLength={10}
              />
            ) : (
              <TouchableOpacity onPress={() => setEditingFirstName(true)}>
                <Text style={styles.profileName}>{firstName}</Text>
              </TouchableOpacity>
            )}
            <Text style={{ marginHorizontal: 5 }}> </Text>
            {editingLastName ? (
              <ExpandingTextInput
                value={lastName}
                onChangeText={setLastName}
                onBlur={handleLastNameBlur}
                autoFocus
                style={styles.profileName}
                maxLength={10}
                placeholder={t.apellido}
                placeholderTextColor={Colors.grayMedium}
              />
            ) : (
              <TouchableOpacity onPress={() => setEditingLastName(true)}>
                <Text
                  style={[
                    styles.profileName,
                    !lastName ? { color: Colors.grayMedium } : {},
                  ]}
                >
                  {lastName || t.apellido}
                </Text>
              </TouchableOpacity>
            )}
          </View>
          <TouchableOpacity
            onPress={showLocationModal}
            style={styles.locationTouchArea}
          >
            <View style={styles.locationContainer}>
              <Image source={pinImage} style={styles.locationIcon} />
              <Text style={styles.profileLocationName}>
                {userData.data[0].zona}
              </Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity onPress={handlePress}>
            <Image source={getMoreIcon()} style={styles.moreIcon} />
          </TouchableOpacity>
          <TouchableOpacity onPress={handleShare}>
            <Image
              source={require("../img/icons/share.png")}
              style={styles.shareIcon}
            />
          </TouchableOpacity>
          <Text style={styles.ratingText}>
            {processRating(userData.data[0].valoracio)}
          </Text>
          <Text style={styles.reviewCount}>
            {`${processCommentCount(userData.data[0].numComentaris)} ${
              t.valoracions
            }`}
          </Text>
          <View style={styles.ratingSeparator} />
          <Image source={puntuationImage} style={styles.ratingIcon} />
          <View style={styles.presentationContainer}>
            <ExpandingTextInput
              ref={textInputRef}
              style={styles.presentationText}
              multiline={true}
              value={presentationText}
              onChangeText={handleTextInputChange}
              maxLength={maxCharLimit}
              returnKeyType="done"
              onFocus={handleFocus}
              onSubmitEditing={handleDoneEditing}
              onBlur={handleDoneEditing}
              textAlignVertical="top"
            />
            <Text style={styles.charCountText}>
              {charCount}/{maxCharLimit}
            </Text>
          </View>
          <TouchableOpacity
            style={styles.proLabelContainer}
            onPress={handleProPress}
          >
            <Text style={proTextStyle}>PRO</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.iconTouchableArea}
            onPress={showInfoModal}
          >
            <Image source={infoImage} style={styles.infoIcon} />
          </TouchableOpacity>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#ffffff",
    alignItems: "center",
    width: "100%",
    marginTop: Platform.OS === "web" ? 15 : 0,
  },
  profileCard: {
    backgroundColor: "#ffffff",
    width: 313,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    paddingVertical: 20,
    marginTop: smallDevice ? 15 : 40,
  },
  profileName: {
    ...Fonts.dmSerifDisplayRegular32,
    color: Colors.negre,
    marginTop: smallDevice ? 10 : 20,
  },
  profileImageContainer: {
    height: "auto",
    width: 100,
    borderRadius: 50,
    overflow: "hidden",
    position: "absolute",
    top: -50,
    justifyContent: "center",
    alignItems: "center",
  },
  cameraIcon: {
    position: "absolute",
    bottom: 5,
    right: 36,
    height: 25,
    width: 25,
    zIndex: 99,
    opacity: 0.9,
  },
  locationTouchArea: {
    padding: 10,
  },
  locationContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: smallDevice ? 18 : 20,
    backgroundColor: Colors.primary,
    borderRadius: 15,
    padding: 5,
  },
  moreIcon: {
    height: 34,
    width: 34,
    position: "absolute",
    top: Platform.select({
      ios: smallDevice ? -190 : -208,
      android: smallDevice ? -181 : -200,
      default: -216,
    }),
    left: 137,
    zIndex: 2,
  },
  shareIcon: {
    height: 32,
    width: 32,
    position: "absolute",
    top: Platform.select({
      ios: smallDevice ? -141 : -152,
      android: smallDevice ? -134 : -145,
      default: -146,
    }),
    left: 136,
    zIndex: 2,
  },
  profileLocationName: {
    ...Fonts.poppinsRegular14,
    color: Colors.white,
    marginLeft: 5,
    marginRight: 5,
  },
  locationIcon: {
    height: 23,
    width: 23,
  },
  ratingText: {
    color: "#000",
    ...Fonts.poppinsRegular12,
    fontSize: Fonts.poppinsRegular12.fontSize,
    position: "absolute",
    top: smallDevice ? 75 : 87,
    left: 118,
  },
  reviewCount: {
    color: Colors.negre,
    ...Fonts.poppinsRegular12,
    fontSize: Fonts.poppinsRegular12.fontSize,
    position: "absolute",
    top: smallDevice ? 75 : 87,
    left: 159,
  },
  ratingSeparator: {
    backgroundColor: "black",
    height: 17,
    width: 1,
    position: "absolute",
    top: smallDevice ? 73 : 83,
    left: 148,
  },
  ratingIcon: {
    height: 17,
    width: 17,
    position: "absolute",
    top: smallDevice ? 72 : 83,
    left: 97,
  },
  presentationContainer: {
    width: 340,
    height: "auto",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    marginBottom: smallDevice ? 5 : 10,
    marginTop: smallDevice ? 0 : 10,
    borderRadius: 16,
    backgroundColor: Colors.white,
    paddingHorizontal: 15,
    paddingVertical: 15,
    ...Platform.select({
      ios: {
        shadowColor: "rgba(0, 0, 0, 0.25)",
        shadowOffset: { width: 1, height: 1 },
        shadowOpacity: 1,
        shadowRadius: 6,
      },
      android: {
        elevation: 6,
      },
      default: {
        shadowColor: "rgba(0, 0, 0, 0.25)",
        shadowOffset: { width: 1, height: 1 },
        shadowRadius: 6,
        shadowOpacity: 1,
      },
    }),
  },
  presentationText: {
    ...Fonts.poppinsRegular16,
    color: Colors.inactive,
    maxHeight: smallDevice ? 40 : "auto",
    width: "100%",
    marginTop: -10,
    marginBottom: -5,
  },
  charCountText: {
    ...Fonts.poppinsRegular12,
    color: Colors.inactive,
    alignSelf: "flex-end",
    marginTop: 5,
  },
  proLabelContainer: {
    position: "absolute",
    top: "7%",
    left: "0%",
    zIndex: 1,
  },
  proTextPremium: {
    fontWeight: "bold",
    fontSize: 16,
    color: Colors.secondary,
  },
  proTextNonPremium: {
    fontWeight: "bold",
    fontSize: 16,
    color: Colors.grayMedium,
  },
  iconTouchableArea: {
    position: "absolute",
    top: "-19.5%",
    left: "0%",
    zIndex: 2,
    padding: 0,
  },
  infoIcon: {
    width: 24,
    height: 24,
  },
  modalOverlay: {
    flex: 1,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    justifyContent: "center",
    alignItems: "center",
  },
  modalContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    borderColor: "green",
    borderWidth: 3,
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginTop: 20,
  },
  singleButtonContainer: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginTop: 20,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
    flex: 1,
    marginHorizontal: 5,
    borderWidth: 2,
    borderColor: "green",
    backgroundColor: "white",
  },
  singleButton: {
    minWidth: "60%",
  },
  buttonClose: {
    borderColor: "green",
  },
  buttonEnable: {
    backgroundColor: "green",
  },
  textStyle: {
    fontWeight: "bold",
    textAlign: "center",
  },
  textClose: {
    marginVertical: 5,
    color: "green",
  },
  textEnable: {
    color: "white",
  },
  modalText: {
    fontSize: 18,
    marginBottom: 15,
    textAlign: "center",
  },
  boldText: {
    fontWeight: "bold",
  },
});

export default ProfileInfo;
