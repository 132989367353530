import React, { useState, useContext } from 'react';
import { View, Text, Image, StyleSheet, TextInput, Platform } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { AppContext } from '../../AppContext';
import { Fonts, Colors } from "../styles/styles.js";

// Objeto de traducción
const translate = {
  ESP: {
    placeholder: "buscar servicio u objeto cerca de ti...",
    focusedPlaceholder: "Limpieza, manitas, parking, sofá...",
  },
  CAT: {
    placeholder: "cercar servei o objecte a prop teu...",
    focusedPlaceholder: "Neteja, manetes, pàrquing, sofà...",
  },
  ENG: {
    placeholder: "search service or object nearby...",
    focusedPlaceholder: "Cleaning, handyman, parking, sofa...",
  }
};


export const Buscar = ({ style }) => {
  const [query, setQuery] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const navigation = useNavigation();
  const { language } = useContext(AppContext);

  const preposiciones = [
    "de", "a", "desde", "hacia", "hasta", "en", "sobre", "por", "para", "sin", 
    "bajo", "contra", "des", "cap a", "fins a", "per", "sense", "sota", "of", 
    "to", "from", "towards", "in", "on", "by", "for", "without", "under", 
    "against", "busco", "mi", "una", "un", "el", "la", "los", "las", "me", "te", 
    "le", "les", "nos", "os", "les", "se", "con", "y", "o", "u", "ni", "que", 
    "cuando", "donde", "como", "quien", "cual", "cuyo", "cuya", "cuyos", 
    "cuyas", "cualquiera", "cualquier", "algun", "alguna", "algunos", "algunas", 
    "ningun", "ninguna", "ningunos", "ningunas", "todo", "toda", "todos", 
    "todas", "mucho", "mucha", "muchos", "muchas", "poco", "poca", "pocos", 
    "pocas", "varios", "varias", "demasiado", "demasiada", "demasiados", 
    "demasiadas", "bastante", "bastanta", "bastantes", "bastantas", "más", 
    "menos", "mejor", "peor", "ante", "tras", "durante", "mediante", "entre", 
    "sobre", "junto", "según", "excepto", "salvo", "incluso", "si", "aunque", 
    "mientras", "tan", "tanto", "tanta", "tantos", "tantas", "pero", "porque", 
    "pues", "como", "sino", "sea", "siempre", "hacia", "hacia", "entonces", 
    "pues", "también", "tampoco", "así", "ya", "aquí", "ahí", "allí", "allá", 
    "acá", "ahora", "hoy", "mañana", "ayer", "entonces", "aún", "todavía", 
    "pronto", "tarde", "temprano", "después", "antes", "luego", "enseguida", 
    "ahora", "mismo", "anoche", "ayer", "hoy", "siempre", "nunca", "jamás", 
    "pronto", "tarde", "temprano", "después", "antes", "luego", "enseguida", 
    "ahora", "mismo", "anoche", "ayer", "hoy", "ahora", "això", "allò", "aquí", 
    "allí", "cap", "aquest", "aquesta", "aquests", "aquestes", "aquell", 
    "aquella", "aquells", "aquelles", "un", "una", "uns", "unes", "en", "amb", 
    "sense", "sobre", "fins", "durant", "mitjançant", "dins", "sota", "segons", 
    "excepte", "inclosos", "si", "encara", "mentre", "però", "perquè", "doncs", 
    "com", "sinó", "més", "menys", "millor", "pitjor", "tant", "tants", "tanta", 
    "tantes", "alguns", "algunes", "cap", "tot", "tots", "tota", "totes"
  ];
  

  const handleSubmit = () => {
    if (query) {
      const words = query.split(" ").filter(word => word.length > 0);
  
      if (words.length === 1 && preposiciones.includes(words[0].toLowerCase())) {
        navigation.navigate('CategoryDetails', { category: { nombre: words } });
      } else {
        const filteredWords = words.filter(word => !preposiciones.includes(word.toLowerCase()));
        navigation.navigate('CategoryDetails', { category: { nombre: filteredWords } });
      }
      setQuery("");
    }
  };

  return (
    <View style={[styles.buscar, style, isFocused && styles.buscarFocused]}>
      <Image
        source={require('../img/icons/lupa.png')}
        style={styles.icon}
        resizeMode='contain'
      />
      <TextInput
        style={[styles.query, isFocused && styles.queryFocused]}
        value={query}
        onChangeText={setQuery}
        onSubmitEditing={handleSubmit}
        placeholder={isFocused ? translate[language].focusedPlaceholder : translate[language].placeholder}
        placeholderTextColor={isFocused ? Colors.grayDark : Colors.grayMedium}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  buscar: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: '#ffffff',
    borderColor: Colors.grayMedium,
    borderWidth: 0.3,
    borderRadius: 24,
    flexDirection: 'row',
    height: 44,
    padding: 7,
    width: 340,
    marginBottom: 0
  },
  buscarFocused: {
    borderColor: Colors.grayDark,
    borderWidth: 0.6,
  },
  icon: {
    width: 16,
    height: 16,
    marginLeft: 8, 
  },
  query: {
    color: Colors.grayMedium, // Color del texto cuando no está en foco
    flex: 1,
    ...Fonts.poppinsRegular16,
    marginLeft: 8, 
    marginBottom: Platform.OS === 'android' ? -3 : 3,
  },
  queryFocused: {
    color: Colors.grayDark, // Color del texto cuando está en foco
  },
});

export default Buscar;
