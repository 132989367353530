// ChatScreen.js
import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import { GiftedChat, Bubble, InputToolbar } from "react-native-gifted-chat";
import {
  View,
  Image,
  Platform,
  TouchableOpacity,
  Text,
  Modal,
  TouchableWithoutFeedback,
  StyleSheet,
  Keyboard,
  TextInput,
  AppState,
  Linking,
} from "react-native";
import { AppContext } from "../../AppContext";
import SocketIOClient from "socket.io-client";
import { useNavigation } from "@react-navigation/native";
import { Fonts, Colors } from "../styles/styles.js";
import * as Haptic from "expo-haptics";
import profileImages from "../img/profileimage.js"; // Importar las imágenes de perfil
import { useFocusEffect } from "@react-navigation/native";

// Importar el componente personalizado MessageText
import MessageText from './MessageText'; // Asegúrate de que la ruta es correcta

// Librería para previsualizar links en mobile
import { getLinkPreview } from "link-preview-js";

const translations = {
  ESP: {
    placeholder: "Escribe un mensaje...",
    back: "Volver",
    message: "Este chat está actualmente bloqueado.",
    desbloquear: "Desbloquear",
    atrás: "Atrás",
    send: "Enviar",
    today: "Hoy",
    block:
      "Tienes bloqueado a este usuario, no podréis mandaros mensajes hasta que lo desbloquees.",
    retry: "Volver a intentar",
    notSent: "No enviado. Toca para volver a intentar.",
  },
  CAT: {
    placeholder: "Escriu un missatge...",
    back: "Tornar",
    message: "Aquest xat està actualment bloquejat.",
    desbloquear: "Desbloquejar",
    atrás: "Enrere",
    send: "Enviar",
    today: "Avui",
    block:
      "Tens aquest usuari bloquejat, no us podreu enviar missatges fins que el desbloquegis.",
    retry: "Tornar a intentar",
    notSent: "No enviat. Toca per tornar a intentar.",
  },
  ENG: {
    placeholder: "Write a message...",
    message: "This chat is currently blocked.",
    back: "Back",
    desbloquear: "Unblock",
    atrás: "Back",
    send: "Send",
    today: "Today",
    block:
      "You have this user blocked, you won't be able to send messages until you unblock them.",
    retry: "Retry",
    notSent: "Not sent. Tap to retry.",
  },
};

// Regex que captura un link tipo "elpais.es", "www.elpais.es", "https://elpais.es", etc.
const linkRegex = /\b((?:https?:\/\/|www\.|[a-zA-Z0-9-]+\.[a-zA-Z]{2,})(?:[^\s]*))/i;

const ChatScreen = ({ route }) => {
  const {
    miID,
    serverAddress,
    token,
    language = "ESP",
  } = useContext(AppContext);

  const userId = miID;
  const t = translations[language];

  const navigation = useNavigation();
  const chatSocketRef = useRef(null);
  const messageTimers = useRef({});
  // Caché para no repetir el fetch de la misma URL
  const linkPreviewCache = useRef({});

  const [isBlocked, setIsBlocked] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [blockIcon, setBlockIcon] = useState(require("../img/icons/noblock.png"));
  const [blockerId, setBlockerId] = useState(null);
  const [appState, setAppState] = useState(AppState.currentState);

  const [messages, setMessages] = useState([]);
  const [text, setText] = useState("");
  const [sendButtonDisabled, setSendButtonDisabled] = useState(true);

  const [hasHistoricalMessages, setHasHistoricalMessages] = useState(false);

  const { otherUserId, otherUserName, otherUserSurname, otherUserPhoto } =
    route.params;
  const fullName = otherUserName
    ? `${otherUserName} ${otherUserSurname || ""}`.trim()
    : "";

  // --- EJEMPLO de API para web (puedes cambiar la URL o usar openGraph.io) ---
  const fetchWebPreview = async (finalUrl) => {
    try {
      const res = await fetch(`https://opengraph.io/api/1.1/site/${encodeURIComponent(finalUrl)}?app_id=8f9e682a-51fd-4b1f-bdbf-9e1dc875eca2`);
      const data = await res.json();
      return {
        url: data.hybridGraph?.url || data.openGraph?.url,
        title: data.hybridGraph?.title || data.openGraph?.title,
        description: data.hybridGraph?.description || data.openGraph?.description,
        images: data.hybridGraph?.image ? [data.hybridGraph.image] : [],
      };
    } catch {
      return null;
    }
  };


  // Función para adjuntar link preview según plataforma
  const attachLinkPreviewIfNeeded = async (msg) => {
    const match = msg.text.match(linkRegex);
    if (!match) return msg; // No hay link

    let finalUrl = match[1];
    if (!finalUrl.startsWith("http")) {
      finalUrl = "https://" + finalUrl;
    }

    if (linkPreviewCache.current[finalUrl]) {
      return { ...msg, linkPreviewData: linkPreviewCache.current[finalUrl] };
    }

    try {
      let data;
      if (Platform.OS === "web") {
        // Llamada a API / endpoint para preview
        data = await fetchWebPreview(finalUrl);
      } else {
        // Mobile => link-preview-js local
        data = await getLinkPreview(finalUrl);
      }
      if (data) {
        linkPreviewCache.current[finalUrl] = data;
        return { ...msg, linkPreviewData: data };
      }
    } catch {}

    return msg;
  };

  const handlePress = () => {
    handleBlockToggle();
    setModalVisible(true);
  };

  const handleBlockToggle = async () => {
    const newBlockState = !isBlocked;
    const resp = await fetch(`${serverAddress}/api/v1/perfils/actuacions`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        actuacio: newBlockState ? "BL" : "UNBL",
        usu_Desti: otherUserId,
        bloquejat: newBlockState ? 1 : 0,
      }),
    });
    const data = await resp.json();
    if (data.status === "OK") {
      setIsBlocked(newBlockState);
      setBlockIcon(
        newBlockState
          ? require("../img/icons/block2.png")
          : require("../img/icons/noblock.png")
      );
      if (!newBlockState) {
        cargarMensajesHistoricos();
      }
    }
  };

  const handleUnblock = async () => {
    try {
      const resp = await fetch(`${serverAddress}/api/v1/perfils/actuacions`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          actuacio: "BL",
          usu_Desti: otherUserId,
          bloquejat: 0,
        }),
      });
      const data = await resp.json();
      if (data.status === "OK") {
        setIsBlocked(false);
        setBlockIcon(require("../img/icons/noblock.png"));
        setModalVisible(false);
        cargarMensajesHistoricos();
      }
    } catch {}
  };

  const handleBackPress = () => navigation.goBack();
  const handleUserPress = (id) => navigation.navigate("ProfileOther", { userId: id });

  const cargarMensajesHistoricos = async () => {
    try {
      const r = await fetch(`${serverAddress}/api/v1/perfils/actuacions`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ actuacio: "LX", usu_Desti: otherUserId }),
      });
      const data = await r.json();
      if (data.status === "OK" && Array.isArray(data.data)) {
        const mens = data.data.map((m) => ({
          _id: String(m.id_missatge),
          text: m.texte,
          createdAt: new Date(m.dataCreacio),
          user: { _id: m.usuariEmisor },
          situacioMissatge: m.situacioMissatge,
          status: "sent",
        }));
        const completos = [];
        for (const ms of mens) {
          completos.push(await attachLinkPreviewIfNeeded(ms));
        }
        const grouped = groupMessagesByDate(completos);
        setMessages(grouped.reverse());
        setHasHistoricalMessages(completos.length > 0);
      } else if (data.data && data.data.status === "BLO") {
        setIsBlocked(true);
        setBlockerId(data.data.usu_bloquejador);
        setBlockIcon(require("../img/icons/block2.png"));
        setModalVisible(true);
        setHasHistoricalMessages(false);
      } else {
        setHasHistoricalMessages(false);
      }
    } catch {}
  };

  const groupMessagesByDate = (msgs) => {
    const sorted = msgs.sort(
      (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
    );
    const grouped = [];
    let prevDate = "";
    sorted.forEach((m, i) => {
      const d = new Date(m.createdAt);
      const ds = d.toDateString();
      if (i === 0 || ds !== prevDate) {
        grouped.push({
          _id: `date-${m._id}`,
          text: formatDate(d, language),
          createdAt: d,
          system: true,
        });
      }
      prevDate = ds;
      grouped.push(m);
    });
    return grouped;
  };

  const formatDate = (d, lang = "ESP") => {
    const dd = new Date(d);
    dd.setHours(0, 0, 0, 0);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    let locale = "en-US";
    if (lang === "ESP") locale = "es-ES";
    if (lang === "CAT") locale = "ca-ES";
    if (dd.getTime() === today.getTime()) {
      return translations[lang].today || "Today";
    }
    return dd.toLocaleDateString(locale, {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  useEffect(() => {
    const handleAppStateChange = (nxt) => {
      if (appState.match(/inactive|background/) && nxt === "active") {
        cargarMensajesHistoricos();
        if (chatSocketRef.current && !chatSocketRef.current.connected) {
          chatSocketRef.current.connect();
        }
      }
      setAppState(nxt);
    };
    const sub = AppState.addEventListener("change", handleAppStateChange);
    return () => sub.remove();
  }, [appState]);

  useEffect(() => {
    navigation.setOptions({
      headerTitle: () => (
        <TouchableOpacity onPress={() => handleUserPress(otherUserId)}>
          <Text
            style={{
              fontWeight: "bold",
              fontSize: 18,
              color: Colors.primary,
              textDecorationLine: "underline",
            }}
          >
            {fullName}
          </Text>
        </TouchableOpacity>
      ),
      headerTitleAlign: "center",
      headerRight: hasHistoricalMessages
        ? () => (
            <TouchableOpacity onPress={handlePress} style={{ paddingRight: 10 }}>
              <Image source={blockIcon} style={{ width: 30, height: 25 }} />
            </TouchableOpacity>
          )
        : null,
      headerLeft: () => (
        <TouchableOpacity onPress={handleBackPress} style={{ marginLeft: 10, width: 80 }}>
          <Text style={{ color: Colors.primary, fontSize: 16 }}>
            {t.back}
          </Text>
        </TouchableOpacity>
      ),
    });
  }, [navigation, fullName, blockIcon, hasHistoricalMessages]);

  useEffect(() => {
    const chatSocket = SocketIOClient(serverAddress, {
      path: "/chat-socket",
      query: { token },
      reconnection: true,
      reconnectionAttempts: 10,
      reconnectionDelay: 2000,
    });
    chatSocketRef.current = chatSocket;

    cargarMensajesHistoricos();

    const joinChat = () => {
      chatSocket.emit("join chat", { userId: miID, otherUserId });
    };

    chatSocket.on("connect", joinChat);
    chatSocket.on("reconnect", joinChat);

    const handleChatMessage = async (incoming) => {
      if (!incoming._id) return;
      const msgWithStatus = {
        ...incoming,
        status: "sent",
        situacioMissatge: incoming.situacioMissatge || "E",
      };
      const msgComplete = await attachLinkPreviewIfNeeded(msgWithStatus);

      setMessages((prev) => {
        const idx = prev.findIndex((m) => String(m._id) === String(msgComplete._id));
        if (idx !== -1) {
          const existingId = prev[idx]._id;
          if (messageTimers.current[existingId]) {
            clearTimeout(messageTimers.current[existingId].waitingTimer);
            clearTimeout(messageTimers.current[existingId].failTimer);
            delete messageTimers.current[existingId];
          }
          const copy = [...prev];
          copy[idx] = { ...copy[idx], ...msgComplete };
          return copy;
        }
        return GiftedChat.append(prev, msgComplete);
      });
    };

    chatSocket.on("chat message", handleChatMessage);

    return () => {
      Object.values(messageTimers.current).forEach(({ waitingTimer, failTimer }) => {
        clearTimeout(waitingTimer);
        clearTimeout(failTimer);
      });
      messageTimers.current = {};

      if (chatSocketRef.current) {
        chatSocketRef.current.off("chat message", handleChatMessage);
        chatSocketRef.current.off("connect", joinChat);
        chatSocketRef.current.off("reconnect", joinChat);
        chatSocketRef.current.emit("leave chat", { userId: miID, otherUserId });
        chatSocketRef.current.disconnect();
        chatSocketRef.current = null;
      }
    };
  }, [miID, otherUserId, serverAddress, token]);

  const onSend = useCallback(
    async (msgArr = []) => {
      setSendButtonDisabled(true);
      setText("");

      for (const m of msgArr) {
        const tempId = `${Date.now()}-${Math.floor(Math.random() * 1000)}`;
        let messageWithId = {
          ...m,
          _id: tempId,
          status: "pending",
          situacioMissatge: "E",
        };
        messageWithId = await attachLinkPreviewIfNeeded(messageWithId);

        setMessages((prev) => GiftedChat.append(prev, messageWithId));

        if (chatSocketRef.current && chatSocketRef.current.connected) {
          chatSocketRef.current.emit("send message", {
            userId,
            otherUserId,
            text: messageWithId.text,
            createdAt: messageWithId.createdAt.toISOString(),
            _id: tempId,
          });
        }
        const waitingTimer = setTimeout(() => {
          updateMessageStatus(tempId, "waiting");
        }, 1000);
        const failTimer = setTimeout(() => {
          updateMessageStatus(tempId, "failed");
        }, 5000);
        messageTimers.current[tempId] = { waitingTimer, failTimer };
      }
    },
    [userId, otherUserId]
  );

  const updateMessageStatus = (id, status) => {
    setMessages((prev) =>
      prev.map((m) =>
        String(m._id) === String(id) ? { ...m, status } : m
      )
    );
  };

  const renderSendButton = (props) => (
    <TouchableOpacity
      style={[
        styles.sendButton,
        { backgroundColor: sendButtonDisabled ? "#d3d3d3" : Colors.primary },
      ]}
      onPress={async () => {
        if (!sendButtonDisabled) {
          try {
            if (Platform.OS !== "web") {
              await Haptic.impactAsync(Haptic.ImpactFeedbackStyle.Heavy);
            }
          } catch {}
          onSend([{ text, user: { _id: userId }, createdAt: new Date() }]);
        }
      }}
      disabled={sendButtonDisabled}
    >
      <Image
        source={require("../img/icons/share3.png")}
        style={styles.sendIcon}
      />
    </TouchableOpacity>
  );

  const handleInputChange = (val) => {
    setText(val);
    setSendButtonDisabled(val.trim() === "");
  };

  // Componente que muestra la previsualización ya adjuntada en "linkPreviewData"
  const LinkPreviewCard = ({ data }) => {
    if (!data) return null;
    const { title, description, images, url } = data;
    const firstImage = images && images.length ? images[0] : null;
    const openLink = () => {
      if (url) Linking.openURL(url).catch(err => console.error('An error occurred', err));
    };
    return (
      <TouchableOpacity onPress={openLink} style={styles.linkPreviewContainer}>
        {firstImage && (
          <Image
            source={{ uri: firstImage }}
            style={styles.linkPreviewImage}
            resizeMode="cover"
          />
        )}
        <View style={{ flex: 1, padding: 8 }}>
          <Text style={styles.linkPreviewTitle} numberOfLines={2}>
            {title || url}
          </Text>
          {!!description && (
            <Text style={styles.linkPreviewDescription} numberOfLines={3}>
              {description}
            </Text>
          )}
        </View>
      </TouchableOpacity>
    );
  };

  const renderBubble = (props) => {
    const { currentMessage } = props;
    let bubbleStyle = {};
    let tickIcon = "";
    let tickColor = "#FFF"; // Blanco por defecto

    if (currentMessage.status === "sent") {
        tickIcon = renderMessageStatusIcon(currentMessage.situacioMissatge);
        tickColor = currentMessage.situacioMissatge === "L" ? "#00FF00" : "#FFF"; // Verde chillón para leídos
    } else if (currentMessage.status === "waiting") {
        bubbleStyle = { opacity: 0.5 };
        tickIcon = "\u231B"; // Reloj de espera
    } else if (currentMessage.status === "failed") {
        bubbleStyle = { opacity: 0.5 };
        tickIcon = "\u231B";
    }

    return (
        <Bubble
            {...props}
            wrapperStyle={{
                right: {
                    ...bubbleStyle,
                    backgroundColor: "#007aff",
                    borderRadius: 16,
                    paddingHorizontal: 12,
                    paddingVertical: 8,
                    maxWidth: "75%",
                    minWidth: 40, // Se adapta a mensajes cortos
                    alignSelf: "flex-end",
                    marginRight: 6, // Separación con el borde derecho
                },
                left: {
                    backgroundColor: Colors.white,
                    borderRadius: 16,
                    paddingHorizontal: 12,
                    paddingVertical: 8,
                    maxWidth: "75%",
                    minWidth: 40,
                    alignSelf: "flex-start",
                    marginLeft: 6, // Separación con el borde izquierdo
                },
            }}
            textStyle={{
                right: {
                    textAlign: "left",
                    marginBottom: 2, // Separación con la hora y ticks
                },
                left: {
                    textAlign: "left",
                    marginBottom: 2,
                },
            }}
            renderTime={(timeProps) => (
                <View style={{ flexDirection: "row", alignItems: "center", marginTop: 2 }}>
                    {/* Hora debajo del mensaje */}
                    <Text style={{ fontSize: 12, color: "#D3D3D3", marginRight: 4 }}>
                        {new Date(currentMessage.createdAt).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}
                    </Text>
                    {/* Ticks alineados a la derecha de la hora */}
                    {currentMessage.user._id === userId && (
                        <Text style={{ fontSize: 12, color: tickColor }}>
                            {tickIcon}
                        </Text>
                    )}
                </View>
            )}
        />
    );
};

const renderMessageStatusIcon = (msgSituacion) => {
    switch (msgSituacion) {
        case "E":
            return "✓";
        case "R":
            return "✓✓";
        case "L":
            return "✓✓";
        default:
            return "";
    }
};




  const handleResendMessage = (m) => {
    if (["sent", "pending"].includes(m.status)) return;
    updateMessageStatus(m._id, "pending");
    if (chatSocketRef.current && chatSocketRef.current.connected) {
      chatSocketRef.current.emit("send message", {
        userId,
        otherUserId,
        text: m.text,
        createdAt: m.createdAt.toISOString(),
        _id: m._id,
      });
    }
    const waitingTimer = setTimeout(() => {
      updateMessageStatus(m._id, "waiting");
    }, 1000);
    const failTimer = setTimeout(() => {
      updateMessageStatus(m._id, "failed");
    }, 5000);
    messageTimers.current[m._id] = { waitingTimer, failTimer };
  };

  const renderMessageContent = (props) => {
    const { currentMessage } = props;
    if (currentMessage.system) {
      return (
        <View style={{ alignItems: "center", marginVertical: 5 }}>
          <Text style={{ color: "grey" }}>{currentMessage.text}</Text>
        </View>
      );
    }
    if (currentMessage.user._id !== userId) {
      let imageSource;
      let additionalStyle = {};
      if (otherUserPhoto) {
        imageSource = { uri: `${serverAddress}/fotos/${otherUserPhoto}` };
      } else {
        imageSource =
          profileImages[otherUserId % 10] ||
          require("../img/profiles/default.png");
        additionalStyle = { backgroundColor: Colors.primary };
      }
      return (
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <TouchableOpacity onPress={() => handleUserPress(otherUserId)}>
            <Image
              source={imageSource}
              style={{
                width: 40,
                height: 40,
                borderRadius: 20,
                marginRight: 8,
                ...additionalStyle,
              }}
            />
          </TouchableOpacity>
          {renderBubble(props)}
        </View>
      );
    } else {
      return renderBubble(props);
    }
  };

  const renderMessage = (props) => {
    const { currentMessage } = props;
    const isFailed = currentMessage.status === "failed";

    return (
      <View style={{ marginVertical: 2 }}>
        {renderMessageContent(props)}
        {isFailed && (
          <TouchableOpacity
            onPress={() => handleResendMessage(currentMessage)}
            style={{ alignSelf: "flex-end", marginRight: 10, marginTop: 4 }}
          >
            <Text style={{ color: "red", fontSize: 12 }}>{t.notSent}</Text>
          </TouchableOpacity>
        )}
        {/* Si el mensaje tiene linkPreviewData => mostrar */}
        {currentMessage.linkPreviewData && (
          <LinkPreviewCard data={currentMessage.linkPreviewData} />
        )}
      </View>
    );
  };

  const renderModal = () => (
    <Modal
      animationType="slide"
      transparent
      visible={modalVisible}
      onRequestClose={() => setModalVisible(false)}
    >
      <TouchableWithoutFeedback onPress={() => Keyboard.dismiss()}>
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <Text style={styles.modalText}>{t.message}</Text>
            {isBlocked && blockerId === miID ? (
              <>
                <Text style={styles.blockMessageText}>{t.block}</Text>
                <View style={styles.buttonContainer}>
                  <TouchableOpacity
                    style={styles.button}
                    onPress={() => {
                      setModalVisible(false);
                      navigation.goBack();
                    }}
                  >
                    <Text style={styles.textStyle}>{t.atrás}</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={[styles.button, styles.unblockButton]}
                    onPress={handleUnblock}
                  >
                    <Text style={styles.textStyle}>{t.desbloquear}</Text>
                  </TouchableOpacity>
                </View>
              </>
            ) : (
              <View style={styles.buttonContainer}>
                <TouchableOpacity
                  style={[styles.button, styles.singleButton]}
                  onPress={() => {
                    setModalVisible(false);
                    navigation.goBack();
                  }}
                >
                  <Text style={styles.textStyle}>{t.atrás}</Text>
                </TouchableOpacity>
              </View>
            )}
          </View>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  );

  const renderInputToolbarCustom = (iprops) => (
    <InputToolbar
      {...iprops}
      containerStyle={styles.inputToolbar}
      primaryStyle={styles.primaryInputContainer}
      renderComposer={(composerProps) => (
        <TextInput
          {...composerProps}
          placeholder={t.placeholder}
          placeholderTextColor="#999"
          multiline
          style={styles.textInput}
          value={text}
          onChangeText={handleInputChange}
          blurOnSubmit={false}
          maxHeight={140}
        />
      )}
      renderSend={renderSendButton}
    />
  );

  const screenStyle = Platform.OS === "ios" ? { marginBottom: 15 } : {};

  return (
    <View style={[{ flex: 1 }, screenStyle]}>
      {renderModal()}

      {Platform.OS === "web" && (
        <View style={styles.webHeader}>
          <TouchableOpacity onPress={handleBackPress} style={{ marginLeft: 10 }}>
            <Text
              style={{
                color: Colors.primary,
                fontSize: 16,
                textDecorationLine: "underline",
                zIndex: 1000,
              }}
            >
              {t.back}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => handleUserPress(otherUserId)}>
            <Text
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: 16,
                color: Colors.primary,
              }}
            >
              {fullName}
            </Text>
          </TouchableOpacity>
          {hasHistoricalMessages ? (
            <TouchableOpacity onPress={handlePress} style={{ marginRight: 10 }}>
              <Image source={blockIcon} style={{ width: 30, height: 25 }} />
            </TouchableOpacity>
          ) : (
            <View style={{ width: 30, height: 25, marginRight: 10 }} />
          )}
        </View>
      )}

      <GiftedChat
        messages={messages}
        onSend={onSend}
        user={{ _id: userId }}
        renderMessage={renderMessage}
        renderInputToolbar={renderInputToolbarCustom}
        renderMessageText={(props) => <MessageText {...props} />}
        placeholder={t.placeholder}
        keyboardShouldPersistTaps="handled"
        isKeyboardInternallyHandled={false}
        minInputToolbarHeight={60}
        alwaysShowSend
        scrollToBottom
        text={text}
        onInputTextChanged={handleInputChange}
        listViewProps={{
          style: { paddingBottom: 20 },
          contentContainerStyle: {
            paddingTop: Platform.select({
              ios: 4,
              android: 12,
              web: 20,
            }),
          },
        }}
      />
    </View>
  );
};

// ---- ESTILOS ----
const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 20,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: "80%",
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center",
    fontSize: 16,
    color: Colors.primary,
    fontFamily: Fonts.poppinsRegular,
  },
  blockMessageText: {
    marginTop: 4,
    marginBottom: 24,
    fontSize: 14,
    color: "grey",
    textAlign: "center",
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
    backgroundColor: Colors.primary,
    flex: 1,
    marginHorizontal: 5,
  },
  singleButton: {
    width: "80%",
  },
  textStyle: {
    color: "white",
    textAlign: "center",
    fontFamily: Fonts.poppinsRegular,
    fontSize: 16,
  },
  unblockButton: {
    backgroundColor: "green",
  },
  inputToolbar: {
    borderTopWidth: 0,
    paddingVertical: 10, // Reducir padding vertical
    paddingHorizontal: 10,
    backgroundColor: "#F7F7F7",
    minHeight: 60,
    marginBottom: 10,
  },
  primaryInputContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    borderRadius: 25,
    paddingHorizontal: 10,
    marginHorizontal: 8,
    borderWidth: 1,
    borderColor: "#E0E0E0",
  },
  textInput: {
    flex: 1,
    fontSize: 16,
    color: "#333",
    padding: 8, // Reducir padding
    paddingTop: 8,
    paddingBottom: 8,
    minHeight: 40,
    maxHeight: 140,
    textAlignVertical: "top",
  },
  sendButton: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.primary,
    borderRadius: 20,
    width: 40,
    height: 40,
    marginHorizontal: -5,
  },
  sendIcon: {
    width: 24,
    height: 24,
    tintColor: "#FFF",
    resizeMode: "contain",
    marginLeft: -2,
  },
  webHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "white",
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: "#ddd",
  },

  // Estilos link preview
  linkPreviewContainer: {
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 8,
    borderColor: "#ddd",
    borderWidth: 1,
    marginTop: 4,
    marginHorizontal: 10,
    backgroundColor: "#fff",
    overflow: "hidden",
  },
  linkPreviewImage: {
    width: 80,
    height: 80,
    backgroundColor: "#f1f1f1",
  },
  linkPreviewTitle: {
    fontSize: 14,
    fontWeight: "bold",
    marginLeft: 8,
    marginTop: 4,
    marginRight: 8,
    color: Colors.primary,
    textDecorationLine: 'underline', // Underline title
  },
  linkPreviewDescription: {
    fontSize: 12,
    marginHorizontal: 8,
    marginBottom: 4,
    color: "#555",
  },
});

export default ChatScreen;
