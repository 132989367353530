import React, { useEffect, useRef, useContext } from "react";
import {
  StyleSheet,
  Text,
  View,
  Animated,
  TouchableOpacity,
  Platform,
  Image,
  PanResponder
} from "react-native";
import { AppContext } from "../../AppContext";
import { Fonts, Colors } from "../styles/styles.js";

const detectOS = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  } else if (/android/i.test(userAgent)) {
    return "Android";
  } else {
    return "desktop";
  }
};

const openAppOrRedirect = () => {
  const appStoreUrl = "https://apps.apple.com/es/app/trocalia/id6478570314";
  const playStoreUrl = "https://play.google.com/store/apps/details?id=com.trocalia";

  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    window.open(appStoreUrl, '_blank'); // Abre en una nueva pestaña para iOS
  } else if (/android/i.test(userAgent)) {
    window.open(playStoreUrl, '_blank'); // Abre en una nueva pestaña para Android
  }
};

const NotificationPlatform = () => {
  const osDetected = detectOS(); // Llamar solo una vez
  const { language } = useContext(AppContext);
  const translateY = useRef(new Animated.Value(-100)).current;

  // Crear una referencia para el PanResponder
  const panResponder = useRef(
    PanResponder.create({
      onStartShouldSetPanResponder: () => true,
      onPanResponderMove: (event, gestureState) => {
        if (gestureState.dy < 0) { // Si el movimiento es hacia arriba
          translateY.setValue(gestureState.dy);
        }
      },
      onPanResponderRelease: (event, gestureState) => {
        if (gestureState.dy < -10) { // Si el deslizamiento hacia arriba es significativo
          hideNotification();
        } else {
          // Si no, retorna la notificación a su posición original
          Animated.spring(translateY, {
            toValue: 0,
            friction: 5,
            useNativeDriver: true,
          }).start();
        }
      },
    })
  ).current;

  // Modificamos las traducciones para utilizar mensajes específicos de la plataforma
  const translations = {
    ESP: {
      iOS: ["🔍 Explora tu comunidad con la App para Apple", "Descargar"],
      Android: ["🔍 Explora tu comunidad con la App para Google", "Descargar"]
    },
    CAT: {
      iOS: ["🔍 Explora la comunitat amb l'App per a Apple", "Descarrega"],
      Android: ["🔍 Explora la comunitat amb l'App per a Google", "Descarrega"]
    },
    ENG: {
      iOS: ["🔍 Explore your community with the App for Apple", "Download"],
      Android: ["🔍 Explore your community with the App for Google", "Download"]
    },
  };
  

  const getMessageForPlatform = () => {
    const translation = translations[language] || translations.ENG;
    return translation[osDetected] || translation.iOS; // Fallback a iOS si no se detecta correctamente
  };

  useEffect(() => {
    // Añade un retraso de 3 segundos antes de iniciar la animación
    const delayToShow = setTimeout(() => {
      if (osDetected === "Android" || osDetected === "iOS") {
        Animated.timing(translateY, {
          toValue: 0,
          duration: 500,
          useNativeDriver: true,
        }).start();

        const timer = setTimeout(() => {
          hideNotification();
        }, 7000);

        return () => {
          clearTimeout(timer);
        };
      }
    }, 4500); // 3 segundos de retraso

    return () => clearTimeout(delayToShow);
  }, [osDetected, translateY]);

  const hideNotification = () => {
    Animated.timing(translateY, {
      toValue: -100,
      duration: 500,
      useNativeDriver: true,
    }).start();
  };

  if (Platform.OS !== "web" || osDetected === "desktop") return null;

  return (
    <Animated.View
      {...panResponder.panHandlers}
      style={[styles.notification, { transform: [{ translateY }] }]}
    >
      <TouchableOpacity
        onPress={openAppOrRedirect}
        accessible
        accessibilityLabel="Open app store"
        accessibilityHint={`Opens the ${osDetected} app store.`}
        style={styles.touchableContent}
      >
        <View style={styles.textLogoContainer}>
          <Text style={styles.text}>
            {getMessageForPlatform()[0]}
            {" "}
            <Text style={styles.downloadText}>
              {getMessageForPlatform()[1]}
            </Text>
          </Text>
          <Image source={require("../img/logo.png")} style={styles.logo} />
        </View>
      </TouchableOpacity>
      <TouchableOpacity onPress={hideNotification} style={styles.closeButton}>
        <Text style={styles.closeButtonText}>✖</Text>
      </TouchableOpacity>
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  notification: {
    position: "absolute",
    marginTop: 40,
    top: Platform.OS === "web" ? -10 : 40, // Ajuste para web y nativo
    left: 20,
    right: 20,
    backgroundColor: Colors.white,
    paddingVertical: 15,
    paddingHorizontal: 15,
    borderRadius: 20, // Esquinas redondeadas para estilo iOS
    borderWidth: 1,
    borderColor: Colors.white,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    zIndex: 1000,
  },
  touchableContent: {
    flex: 1,
  },
  textLogoContainer: {
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
  },
  text: {
    flex: 1,
    color: Colors.primary,
    ...Fonts.poppinsMedium14,
  },
  downloadText: {
    textDecorationLine: "underline",
  },
  logo: {
    width: 40,
    height: 40,
    marginLeft: 10,
  },
  closeButton: {
    padding: 10,
  },
  closeButtonText: {
    color: Colors.primary,
    fontSize: 18,
  },
});

export default NotificationPlatform;
