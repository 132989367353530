import React, { useContext, useState, useEffect, useRef } from "react";
import {
  View,
  Text,
  StyleSheet,
  Image,
  TouchableOpacity,
  Platform,
  Alert,
  Modal,
  Animated,
  Easing,
} from "react-native";
import { Fonts, Colors } from "../styles/styles.js";
import { AppContext } from "../../AppContext";
import Pro from "../components/Pro.js"; // Asegúrate de que la ruta es correcta
import addImage from "../img/icons/add2.png";
import * as Haptic from "expo-haptics";

// Objeto de traducción
const translate = {
  ESP: {
    ofrece:
      "Ofrece un servicio u objeto a la comunidad (¿Qué ofreces?)",
    necesita:
      "Demanda un servicio u objeto a la comunidad (¿Qué necesitas?)",
    limiteAlcanzado:
      "Has alcanzado el límite de ofertas permitidas para tu tipo de cuenta. Puedes pausar, eliminar ofertas o mejorar tu perfil a PRO.",
    tituloLimiteAlcanzado: "Límite Alcanzado",
    cerrar: "Cerrar",
    verPro: "Mirar ventajas PRO",

    // Alert de notificaciones de correo
    mailAlertTitle: "Notificaciones por correo desactivadas",
    mailAlertMessage:
      "Para poder publicar necesitas tener activadas las notificaciones por correo para recibir los mensajes de vecinos, etc. ¿Deseas activarlas ahora?",
    mailAlertActivate: "Activar",
    mailAlertCancel: "Cerrar",
  },
  CAT: {
    ofrece:
      "Ofereix un servei o objecte a la comunitat (Què ofereixes?)",
    necesita:
      "Demanda de servei o objecte a la comunitat (Què necessites?)",
    limiteAlcanzado:
      "Has assolit el límit d'ofertes permeses per al teu tipus de compte. Pots pausar, eliminar ofertes o millorar el teu perfil a PRO.",
    tituloLimiteAlcanzado: "Límit Assolit",
    cerrar: "Tancar",
    verPro: "Veure avantatges PRO",

    // Alert de notificaciones de correo
    mailAlertTitle: "Notificacions per correu desactivades",
    mailAlertMessage:
      "Per poder publicar necessites tenir activades les notificacions per correu per rebre els missatges dels veïns, etc. Vols activar-les ara?",
    mailAlertActivate: "Activar",
    mailAlertCancel: "Tancar",
  },
  ENG: {
    ofrece:
      "Offer a service or object to the community (What do you offer?)",
    necesita:
      "Add a need for a service or object (What do you need?)",
    limiteAlcanzado:
      "You have reached the limit of offers allowed for your account type. You can pause or delete offers or upgrade your profile to PRO.",
    tituloLimiteAlcanzado: "Limit Reached",
    cerrar: "Close",
    verPro: "View PRO benefits",

    // Alert de notificaciones de correo
    mailAlertTitle: "Email notifications are disabled",
    mailAlertMessage:
      "To be able to post, you need to enable email notifications so you can receive messages from neighbors, etc. Do you want to enable them now?",
    mailAlertActivate: "Enable",
    mailAlertCancel: "Close",
  },
};

// Función auxiliar para dividir el texto en principal y ejemplo
const splitText = (text) => {
  const regex = /^(.*?)\s*\((.*?)\)$/;
  const match = text.match(regex);
  if (match) {
    return { main: match[1], example: match[2] };
  }
  return { main: text, example: "" };
};

const FirstOffer = ({ onPress, selectedOption }) => {
  const { userData, language, serverAddress, token, refreshUserData } =
    useContext(AppContext);

  const [isProVisible, setIsProVisible] = useState(false);
  const [isWebModalVisible, setWebModalVisible] = useState(false);

  // Animaciones
  const fadeAnim = useRef(new Animated.Value(0)).current; // Opacidad para fadeIn
  const bounceAnim = useRef(new Animated.Value(1)).current; // Escala para bounce
  const scaleAnim = useRef(new Animated.Value(1)).current; // Escala para animación periódica
  const rotateAnim = useRef(new Animated.Value(0)).current; // Rotación para icono

  useEffect(() => {
    // Animación de fadeIn para el contenedor principal
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 800,
      useNativeDriver: true,
    }).start();

    // Animación de bounce para el icono
    Animated.sequence([
      Animated.timing(bounceAnim, {
        toValue: 1.2,
        duration: 300,
        easing: Easing.out(Easing.ease),
        useNativeDriver: true,
      }),
      Animated.timing(bounceAnim, {
        toValue: 1,
        duration: 300,
        easing: Easing.out(Easing.ease),
        useNativeDriver: true,
      }),
    ]).start();

    // Animación periódica para el contenedor principal y el icono
    const startPeriodicAnimations = () => {
      // Animación de escalado del contenedor
      Animated.sequence([
        Animated.timing(scaleAnim, {
          toValue: 1.05, // Ampliar ligeramente
          duration: 1800,
          easing: Easing.out(Easing.ease),
          useNativeDriver: true,
        }),
        Animated.timing(scaleAnim, {
          toValue: 1, // Volver al tamaño original
          duration: 800,
          easing: Easing.out(Easing.ease),
          useNativeDriver: true,
        }),
      ]).start();

      // Animación de rotación del icono
      Animated.sequence([
        Animated.timing(rotateAnim, {
          toValue: 1, // Completar 4 vueltas (360 * 4)
          duration: 2000,
          easing: Easing.linear,
          useNativeDriver: true,
        }),
        Animated.timing(rotateAnim, {
          toValue: 0,
          duration: 0,
          useNativeDriver: true,
        }),
      ]).start();
    };

    // Iniciar animaciones periódicas después de 4 segundos
    const animationTimeout = setTimeout(() => {
      startPeriodicAnimations();
      // Configurar interval para repetir cada 10 segundos
      const interval = setInterval(() => {
        startPeriodicAnimations();
      }, 10000);

      // Limpiar el interval al desmontar
      return () => clearInterval(interval);
    }, 4000);

    // Limpiar animaciones al desmontar
    return () => {
      fadeAnim.stopAnimation();
      bounceAnim.stopAnimation();
      scaleAnim.stopAnimation();
      rotateAnim.stopAnimation();
      clearTimeout(animationTimeout);
    };
  }, [fadeAnim, bounceAnim, scaleAnim, rotateAnim]);

  // Interpolación para la rotación del icono
  const rotateInterpolate = rotateAnim.interpolate({
    inputRange: [0, 1],
    outputRange: ["0deg", "1440deg"], // 4 vueltas (360*4)
  });

  const openPro = () => {
    setWebModalVisible(false);
    setIsProVisible(true);
  };

  const closeModal = () => {
    setIsProVisible(false);
    setWebModalVisible(false);
  };

  const showLimitAlert = () => {
    Alert.alert(
      translate[language].tituloLimiteAlcanzado,
      translate[language].limiteAlcanzado,
      [
        { text: translate[language].verPro, onPress: openPro },
        {
          text: translate[language].cerrar,
          onPress: closeModal,
          style: "cancel",
        },
      ],
      { cancelable: true }
    );
  };

  /**
   * Lógica para comprobar si el usuario ha alcanzado el límite
   * y si tiene activado el correo. Si no lo tiene, se le pregunta
   * si quiere activarlo.
   */
  const checkOfferLimit = async () => {
    try {
      if (Platform.OS !== "web") {
        // Añade la respuesta háptica al presionar
        await Haptic.impactAsync(Haptic.ImpactFeedbackStyle.Heavy);
      }
    } catch (error) {
      //console.log("Haptic feedback no está disponible o ha fallado", error);
    }

    if (selectedOption === "ofrece") {
      const activeOffers = userData?.data[0]?.mevesOfertes?.filter(
        (offer) => offer.situacioOferta === "A"
      ).length;
      const maxAllowedOffers = userData?.data[0]?.maxOfertes_Permesses;

      // PRIMERA REVISIÓN: límite de ofertas
      if (activeOffers >= maxAllowedOffers) {
        Platform.OS === "web" ? setWebModalVisible(true) : showLimitAlert();
        return;
      }
    }

    // SEGUNDA REVISIÓN: notificaciones de correo
    const mailEnabled =
      userData && userData.data && userData.data[0]?.notif_email === 1;

    if (!mailEnabled) {
      Alert.alert(
        translate[language].mailAlertTitle,
        translate[language].mailAlertMessage,
        [
          {
            text: translate[language].mailAlertActivate,
            onPress: async () => {
              try {
                // Activar las notificaciones de correo
                const response = await fetch(
                  `${serverAddress}/api/v1/perfils/actuacions`,
                  {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                      actuacio: "GN", // Modifica configuración de notificaciones
                      notif_email: 1, // Activa el correo
                      notif_push:
                        userData?.data[0]?.notif_push === 1 ? 1 : 0, // Mantiene estado push
                    }),
                  }
                );

                if (!response.ok) {
                  console.error("Error al activar emails en el servidor");
                  return;
                }

                // Si la activación fue exitosa, refresca los datos del usuario
                await refreshUserData();

                // Ahora sí, abre el modal (onPress)
                onPress();
              } catch (error) {
                console.error("Error al activar emails:", error);
              }
            },
          },
          {
            text: translate[language].mailAlertCancel,
            onPress: () => {},
            style: "cancel",
          },
        ],
        { cancelable: true }
      );
    } else {
      // Si ya tiene mail activado, abrimos directamente
      onPress();
    }
  };

  // Eliminamos las animaciones personalizadas para el WebModal y ProModal
  // y alineamos la implementación con NewOffer
  const WebModal = () => (
    <Modal
      animationType="slide"
      transparent={true}
      visible={isWebModalVisible}
      onRequestClose={closeModal}
    >
      <TouchableOpacity
        style={styles.centeredView}
        onPress={closeModal}
        activeOpacity={1}
      >
        <View style={styles.modalView}>
          <Text style={styles.modalTitle}>
            {translate[language].tituloLimiteAlcanzado}
          </Text>
          <Text style={styles.modalMessage}>
            {translate[language].limiteAlcanzado}
          </Text>
          <View style={styles.buttonContainer}>
            <TouchableOpacity style={styles.button} onPress={closeModal}>
              <Text style={styles.buttonText}>
                {translate[language].cerrar}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.button} onPress={openPro}>
              <Text style={styles.buttonText}>
                {translate[language].verPro}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </TouchableOpacity>
    </Modal>
  );

  const ProModal = () => (
    <Modal
      animationType="slide"
      transparent={true}
      visible={isProVisible}
      onRequestClose={closeModal}
    >
      <TouchableOpacity
        style={{ flex: 1 }}
        onPress={closeModal}
        activeOpacity={1}
      >
        <Pro closeModal={closeModal} />
      </TouchableOpacity>
    </Modal>
  );

  return (
    <>
      <Animated.View
        style={[
          styles.frame,
          { opacity: fadeAnim, transform: [{ scale: scaleAnim }] },
        ]}
      >
        <TouchableOpacity
          onPress={checkOfferLimit}
          activeOpacity={0.7}
          accessible={true}
          accessibilityLabel={translate[language][selectedOption]}
        >
          {/* Borde azul externo */}
          <View style={styles.wrapperContainer}>
            {/* Borde blanco */}
            <View style={styles.outerWrapper}>
              {/* Fondo azul interno */}
              <Animated.View style={styles.div}>
                <View style={styles.textContainer}>
                  <Text style={styles.textWrapper}>
                    {splitText(translate[language][selectedOption]).main}
                  </Text>
                  {splitText(translate[language][selectedOption]).example !==
                    "" && (
                    <Text style={styles.subtitle}>
                      {splitText(translate[language][selectedOption]).example}
                    </Text>
                  )}
                </View>
                <Animated.Image
                  source={addImage}
                  style={[
                    styles.add,
                    {
                      transform: [
                        { scale: bounceAnim },
                        { rotate: rotateInterpolate },
                      ],
                    },
                  ]}
                />
              </Animated.View>
            </View>
          </View>
        </TouchableOpacity>
      </Animated.View>

      {Platform.OS === "web" && <WebModal />}
      <ProModal />
    </>
  );
};

const styles = StyleSheet.create({
  frame: {
    width: 340, // Ancho original
    height: 67,
    marginBottom: 20,
    marginTop: 10,
    alignSelf: "center",
  },
  div: {
    borderRadius: 18, // Bordes redondeados
    backgroundColor: Colors.primary, // Fondo azul interno
    height: 67,
    width: 327,
    position: "relative",
    alignItems: "center",
    flexDirection: "row",
    paddingHorizontal: 20, // Espaciado horizontal para el contenido
    borderWidth: 4, // Borde externo azul
    borderColor: Colors.primary, // Azul externo
    overflow: "hidden",
  },
  outerWrapper: {
    backgroundColor: Colors.white, // Fondo blanco del borde intermedio
    borderRadius: 22, // Bordes redondeados más grandes que el interno
    padding: 5, // Simular borde blanco
  },
  wrapperContainer: {
    backgroundColor: Colors.primary, // Fondo azul del borde externo
    borderRadius: 24, // Bordes redondeados externos
    padding: 2, // Espaciado para encapsular todo
  },

  textContainer: {
    flex: 1,
    justifyContent: "center", // Centrar verticalmente
    paddingRight: 50, // Espacio para el icono
  },
  textWrapper: {
    color: Colors.white,
    ...Fonts.poppinsMedium16,
    lineHeight: 20,
    fontWeight: "600",
  },
  subtitle: {
    fontSize: 12,
    lineHeight: 16,
    color: Colors.white,
    marginTop: 2,
    opacity: 0.8,
  },
  add: {
    height: 34, // Tamaño original
    width: 34,
    position: "absolute",
    right: 15,
  },
  modalView: {
    backgroundColor: Colors.white,
    borderRadius: 20,
    padding: 20,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: "80%",
    maxHeight: "80%", // Asegurar que el modal no se corte
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 15,
    color: Colors.primary,
    textAlign: "center",
  },
  modalMessage: {
    fontSize: 16,
    textAlign: "center",
    marginBottom: 20,
    color: Colors.grayDark,
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    width: "100%",
  },
  button: {
    borderRadius: 20,
    paddingVertical: 10,
    paddingHorizontal: 20,
    elevation: 2,
    backgroundColor: Colors.primary,
    marginHorizontal: 5,
    flex: 1,
  },
  buttonText: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
    ...Fonts.poppinsMedium14,
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
});

export default FirstOffer;
