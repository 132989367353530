// MessageText.js
import React from "react";
import { Text, Linking } from "react-native";
import ParsedText from "react-native-parsed-text";
import { Fonts, Colors } from "../styles/styles.js";

// Manejar la pulsación de URLs
const handleUrlPress = (url) => {
  // Asegurarse de que la URL tenga el protocolo
  const formattedUrl = url.startsWith("http") ? url : `http://${url}`;
  Linking.openURL(formattedUrl).catch((err) =>
    console.error("An error occurred", err)
  );
};

// Manejar la pulsación de números de teléfono
const handlePhonePress = (phone) => {
  // Eliminar cualquier carácter no numérico
  const cleanedPhone = phone.replace(/\D/g, "");
  if (cleanedPhone.length === 9) {
    // Asumiendo que el código de país es +34 para España
    const whatsappUrl = `https://wa.me/34${cleanedPhone}`;
    Linking.openURL(whatsappUrl).catch((err) =>
      console.error("An error occurred", err)
    );
  } else {
    // Fallback a enlace tel:
    Linking.openURL(`tel:${cleanedPhone}`).catch((err) =>
      console.error("An error occurred", err)
    );
  }
};

// Manejar la pulsación de correos electrónicos
const handleEmailPress = (email) => {
  Linking.openURL(`mailto:${email}`).catch((err) =>
    console.error("An error occurred", err)
  );
};

const MessageText = (props) => {
  const { currentMessage, position, textStyle, customTextStyle } = props;

  const parsePatterns = [
    // URLs con una expresión regular más amplia
    {
      pattern: /((https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/\S*)?)/gi,
      style: { color: Colors.primary, textDecorationLine: "underline" },
      onPress: handleUrlPress,
    },
    // Números de teléfono que pueden contener espacios, guiones u otros separadores
    {
      pattern: /(\b\d{2,3}[\s-]?\d{3}[\s-]?\d{3,4}\b)/,
      style: { color: Colors.primary, textDecorationLine: "underline" },
      onPress: handlePhonePress,
    },
    // Emails
    {
      type: "email",
      style: { color: Colors.primary, textDecorationLine: "underline" },
      onPress: handleEmailPress,
    },
  ];

  return (
    <ParsedText
      style={[
        styles[position].text,
        textStyle && textStyle[position],
        customTextStyle,
      ]}
      parse={parsePatterns}
      childrenProps={{ selectable: true }}
      selectable={true}
    >
      {currentMessage.text}
    </ParsedText>
  );
};

const styles = {
  left: {
    text: {
      color: Colors.black,
      fontSize: 16,
    },
  },
  right: {
    text: {
      color: Colors.white,
      fontSize: 16,
    },
  },
};

export default MessageText;
